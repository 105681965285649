export default function FirstSection({ mobile }) {
	const LegionInfos = [
		'O Hardwork já tem seu primeiro programa de embaixadores!',
		'Na verdade, esse não é um simples programa de embaixadores... O aluno de medicina merece mais; a medicina merece mais.',
		'A Legião Hardwork é um movimento de conexão intensa entre mestre e aprendiz. O mestre contribui profundamente com a tua formação; tu te conectas aos valores da Medicina Hardwork e auxilia na disseminação da nossa forma de ver a educação médica.',
		'Na Legião Hardwork tu não caminharás só pela medicina. Nós iremos contigo.',
		'Selecionaremos alguns alunos de medicina de todo o Brasil para integrarem o primeiro grupo da Legião Hardwork.',
		'A primeira etapa da seleção exige que tu preenchas o formulário na página. Em breve entraremos em contato com detalhes adicionais, como as próximas etapas e todos os benefícios de ser membro da Legião Hardwork.',
		'Estamos aqui para revolucionar.',
	];
	return (
		<section
			style={{
				width: mobile ? '100%' : '55%',
				height: '100%',
				padding: mobile ? '17px 16px' : '52px 120px',
				display: 'flex',
				flexDirection: 'column',
				gap: mobile ? '16px' : '32px',
				backgroundColor: mobile ? 'black' : 'rgba(0, 0, 0, 0.5)',
				backdropFilter: 'blur(30px)',
				WebkitBackdropFilter: 'blur(10px)',
				marginTop: mobile && '3.5rem',
			}}
		>
			{/* DIV TÍTULO */}
			<div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
				<p style={{ fontWeight: '800', fontSize: mobile ? '32px' : '44px' }}>LEGIÃO HARDWORK </p>
				<div style={{ width: '100%', height: '1px', backgroundColor: '#B3B3B3', display: mobile && 'none' }} />
			</div>
			{/* CORPO DO TEXTO */}
			<div
				style={{
					fontSize: mobile ? '14px' : '21px',
					fontWeight: '400',
					color: '#CCCCCC',
					display: 'flex',
					flexDirection: 'column',
					gap: '25px',
				}}
			>
				{LegionInfos.map((e, i) => (
					<p key={i}>{e}</p>
				))}
				<div>
					<p>Abraço,</p>
					<p>Fabrício Valois.</p>
				</div>
			</div>
		</section>
	);
}
