import React, { useState, useRef, useEffect } from 'react';
import {
	ClickAwayListener,
	Container,
	Divider,
	FormControlLabel,
	Grid,
	List,
	ListItem,
	ListItemText,
	Popper,
	Radio,
	RadioGroup,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { trackPromise } from 'react-promise-tracker';
import Api from '../../Services/Api';
import Footer from '../../Components/Footer';
import { Header } from '../../Components/Header';
import DefaultButton from '../../Components/Buttons/DefaultButton';
import { faculdades } from '../../Components/Utils/TemplatesContent/faculdades';
import { useNavigate } from 'react-router-dom';
import '../../../src/App.css';
import { color, height, lineHeight, maxHeight, minHeight } from '@mui/system';

const FormSlide = React.forwardRef(({ setNome, setEmail, setTelefone }) => {
	const [telefoneError, setTelefoneError] = React.useState('');
	const [emailError, setEmailError] = React.useState('');

	const validateTelefone = (telefone) => {
		const telefoneRegex = /^\([1-9]{2}\) [9]{1}[0-9]{4}-[0-9]{4}$/;
		if (!telefoneRegex.test(telefone)) {
			setTelefoneError('Telefone inválido. Exemplo: (11) 91234-5678');
		} else {
			setTelefoneError('');
		}
	};

	const validateEmail = (email) => {
		const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		if (!emailRegex.test(email)) {
			setEmailError('Email inválido. Exemplo: exemplo@email.com');
		} else {
			setEmailError('');
		}
	};

	const formatarTelefone = (valor) => {
		const numeros = valor.replace(/\D/g, '');
		if (numeros.length <= 10) {
			return numeros.replace(/(\d{2})(\d{4})(\d{0,4})/, '($1) $2-$3');
		} else {
			return numeros.replace(/(\d{2})(\d{5})(\d{0,4})/, '($1) $2-$3');
		}
	};

	const handleTelefoneChange = (e) => {
		const valorFormatado = formatarTelefone(e.target.value);
		setTelefone(valorFormatado);
		validateTelefone(valorFormatado);
	};

	const handleEmailChange = (e) => {
		const email = e.target.value;
		setEmail(email);
		validateEmail(email);
	};

	return (
		<Grid
			justifyContent={'center'}
			alignItems={'center'}
			item
			container
			direction="column"
			style={{ paddingTop: '1rem' }}
		>
			<form>
				<Grid
					xl={10}
					item
					width={'27rem'}
					marginBottom={'1rem'}
				>
					<TextField
						variant="outlined"
						label="Seu nome completo"
						onChange={(e) => setNome(e.target.value)}
						fullWidth
						required
						style={{ backgroundColor: '#ffffff10', color: '#fff', borderRadius: '8px' }}
						InputProps={{
							style: { color: '#fff' },
							inputProps: {
								style: { color: '#fff' },
							},
						}}
						InputLabelProps={{ style: { color: '#fff' } }}
					/>
				</Grid>
				<Grid
					xl={10}
					item
					fullWidth
					marginBottom={'1rem'}
				>
					<TextField
						variant="outlined"
						label="Seu melhor e-mail"
						type="email"
						onChange={handleEmailChange}
						fullWidth
						required
						placeholder="exemplo@email.com"
						style={{ backgroundColor: '#ffffff10', color: '#fff', borderRadius: '8px' }}
						InputProps={{
							style: { color: '#fff' },
							inputProps: {
								style: { color: '#fff' },
							},
						}}
						InputLabelProps={{ style: { color: '#fff' } }}
						error={!!emailError}
						helperText={emailError}
					/>
				</Grid>
				<Grid
					xl={10}
					item
					width={'full'}
				>
					<TextField
						variant="outlined"
						label="Seu celular com DDD"
						onChange={handleTelefoneChange}
						fullWidth
						required
						placeholder="Seu celular com DDD"
						style={{ backgroundColor: '#ffffff10', color: '#fff', borderRadius: '8px' }}
						InputProps={{
							style: { color: '#fff' },
							inputProps: {
								style: { color: '#fff' },
							},
						}}
						InputLabelProps={{ style: { color: '#fff' } }}
						error={!!telefoneError}
						helperText={telefoneError}
					/>
				</Grid>
			</form>
		</Grid>
	);
});

const QuestionSlideWithSearch = ({ classes, step, onHandleAnswer, selectedFaculdade, setSelectedFaculdade }) => {
	const [searchValue, setSearchValue] = useState('');
	const faculdades = [
		'UNIT-ARACAJU - UNIVERSIDADE TIRADENTES - ARACAJU',
		'UFRN-NATAL - UNIVERSIDADE FEDERAL DO RIO GRANDE DO NORTE - NATAL',
		'UNIFOR - UNIVERSIDADE DE FORTALEZA',
		'UNIRG - FUNDAÇÃO UNIVERSIDADE REGIONAL DE GURUPI',
		'CESMAC - CENTRO UNIVERSITÁRIO CESMAC',
		'FAMENE - FACULDADE DE MEDICINA NOVA ESPERANÇA',
		'UNIPÊ - CENTRO UNVERSITARIO DE JOÃO PESSOA',
		'UFS-SÃOCRISTOVÃO - UNIVERSIDADE FEDERAL DE SERGIPE',
		'UFC-FORTALEZA - UNIVERSIDADE FEDERAL DO CEARÁ',
		'UFMA-IMPERATRIZ - UNIVERSIDADE FEDERAL DO MARANHÃO - IMPERATRIZ',
		'UNP - UNIVERSIDADE POTIGUAR',
		'ESTÁCIO-JUAZEIRODONORTE - FACULDADE DE MEDICINA DE JUAZEIRO DO NORTE- CE',
		'UFPE-RECIFE - UNIVERSIDADE FEDERAL DE PERNAMBUCO - RECIFE',
		'UFMA-SÃOLUÍS - UNIVERSIDADE FEDERAL DO MARANHÃO - SÃO LUIS',
		'FPS - FACULDADE PERNAMBUCANA DE SAÚDE',
		'UFAL - UNIVERSIDADE FEDERAL DE ALAGOAS',
		'UNICEUMA - CENTRO UNIVERSITÁRIO DO MARANHÃO',
		'FSM - FACULDADE SANTA MARIA',
		'UFPB - UNIVERSIDADE FEDERAL DA PARAÍBA',
		'UNINTA - CENTRO UNIVERSITÁIO UNINTA',
		'UERN - UNIVERSIDADE DO ESTADO DO RIO GRANDE DO NORTE',
		'UNIFAP - UNIVERSIDADE FEDERAL DO AMAPÁ',
		'UFPI-TERESINA - UNIVERSIDADE FEDERAL DO PIAUÍ - TERESINA',
		'UNIT-MACEIÓ - UNIVERSIDADE TIRADENTES - MACEIÓ',
		'UFC-SOBRAL - UNIVERSIDADE FEDERAL DO CEARÁ - SOBRAL',
		'UNICHRISTUS - CENTRO UNIVERSITÁRIO CHRISTUS - FORTALEZA - CE - UNICHRISTUS',
		'FITS - FACULDADE TIRADENTES',
		'UFMA-PINHEIRO - UNIVERSIDADE FEDERAL DO MARANHÃO - PINHEIRO',
		'UFRN-CAICÓ - UNIVERSIDADE FEDERAL DO RIO GRANDE DO NORTE - CAICÓ',
		'UNIVASF-PETROLINA - UNIVERSIDADE FEDERAL DO VALE DO SÃO FRANCISCO - PETROLINA',
		'UNINASSAU - CENTRO UNIVERSITÁRIO MAURICIO DE NASSAU',
		'FACID - FACULDADE INTEGRAL DIFERENCIAL',
		'UFCG-CAJAZEIRAS - UNIVERSIDADE FEDERAL DE CAMPINA GRANDE - CAJAZEIRAS',
		'UFS-LAGARTO - UNIVERSIDADE FEDERAL DE SERGIPE - CAMPUS LAGARTO',
		'UFPE-CARUARU - UNIVERSIDADE FEDERAL DO PERNAMBUCO - CARUARU',
		'UNIFIP - CENTRO UNIVERSITÁRIO DE PATOS',
		'FMO - FACULDADE DE MEDICINA DE OLINDA',
		'UNICAP - UNIVERSIDADE CATÓLICA DE PERNAMBUCO - UNICAP',
		'UPE-RECIFE - UNIVERSIDADE DE PERNAMBUCO',
		'UESPI - UNIVERSIDADE ESTADUAL DO PIAUÍ',
		'UFAL-ARAPIRACA - UNIVERSIDADE FEDERAL DE ALAGOAS - ARAPIRACA',
		'UFCA - UNIVERSIDADE FEDERAL DO CARIRI',
		'UFPI-PARNAÍBA - UNIVERSIDADE FEDERAL DO PIAUÍ - PARNAÍBA',
		'UFT - UNIVERSIDADE FEDERAL DO TOCANTINS',
		'UPE-SERRATALHADA - UNIVERSIDADE DE PERNAMBUCO - UPE / SERRA TALHADA (PE)',
		'UPE-GARANHUNS - UNIVERSIDADE DE PERNAMBUCO / GARANHUNS',
		'UNCISAL - UNIVERSIDADE ESTADUAL DE CIÊNCIAS DA SAÚDE DE ALAGOAS',
		'UECE - UNIVERSIDADE ESTADUAL DO CEARÁ',
		'UEMA - UNIVERSIDADE ESTADUAL DO MARANHÃO',
		'UFCG-CAMPINAGRANDE - UNIVERSIDADE FEDERAL DE CAMPINA GRANDE',
		'UFPI-PICOS - UNIVERSIDADE FEDERAL DO PIAUÍ - PICOS',
		'CESUPA - CENTRO UNIVERSITÁRIO DO ESTADO DO PARÁ',
		'UNIG-IGUAÇU - UNIVERSIDADE IGUAÇU - NOVA IGUAÇU',
		'UFRR - UNIVERSIDADE FEDERAL DE RORAIMA',
		'UNIFESO-TERESÓPOLIS - CENTRO UNIVERSITÁRIO SERRA DOS ORGAOS - TERESOPOLIS',
		'UEA - UNIVERSIDADE DO ESTADO DO AMAZONAS',
		'UFPA - UNIVERSIDADE FEDERAL DO PARÁ',
		'UNIG-ITAPERUNA - UNIVERSIDADE IGUAÇÚ - ITAPERUNA',
		'UNESA-PRESIDENTEVARGAS - UNIVERSIDADE ESTACIO DE SÁ - PRESIDENTE VARGAS',
		'UFAM - UNIVERSIDADE FEDERAL DO AMAZONAS',
		'FMV - FACULDADE DE MEDICINA DE VALENÇA',
		'UNINORTE - FACULDADE BARÃO DO RIO BRANCO - RIO BRANCO / AC - UNINORTE / FAB',
		'UNL - UNIVERSIDADE NILTON LINS',
		'UNIFACS - UNIVERSIDADE SALVADOR',
		'UNIVAG - CENTRO UNIVERSITÁRIO DE VÁRZEA GRANDE',
		'UNIFOA - CENTRO UNIVERSITÁRIO DE VOLTA REDONDA',
		'FASB - FACULDADE SÃO FRANCISCO DE BARREIRAS',
		'FMC - FACULDADE DE MEDICINA DE CAMPOS',
		'UNIC-CUIABÁ - UNIVERSIDADE DE CUIABÁ',
		'UV - UNIVERSIDADE SEVERINO SOMBRA - VASSOURAS',
		'UFAC - UNIVERSIDADE FEDERAL DO ACRE',
		'UEPA-BELÉM - UNIVERSIDADE DO ESTADO DO PARÁ - BELÉM',
		'EBMSP - ESCOLA BAHIANA DE MEDICINA E SAÚDE PÚBLICA',
		'UEPA-MARABÁ - UNIVERSIDADE DO ESTADO DO PARÁ - UEPA / MARABÁ',
		'FTC-SALVADOR - FACULDADE DE TECNOLOGIA E CIENCIA',
		'UNIFG - FACULDADE GUANAMBI',
		'UFMT-CUIABÁ - UNIVERSIDADE FEDERAL DE MATO GROSSO - CUIABÁ',
		'UNIRIO - UNIVERSIDADE FEDERAL DO ESTADO DO RIO DE JANEIRO',
		'UFRJ-MACAÉ - UNIVERSIDADE FEDERAL DO RIO DE JANEIRO - MACAE',
		'UFRJ-RIODEJANEIRO - UNIVERSIDADE FEDERAL DO RIO DE JANEIRO - RIO DE JANEIRO',
		'UFBA-SALVADOR - UNIVERSIDADE FEDERAL DA BAHIA',
		'UFPA-ALTAMIRA - UNIVERSIDADE FEDERAL DO PARÁ - ALTAMIRA',
		'UFF - UNIVERSIDADE FEDERAL FLUMINENSE',
		'FAMAZ - FACULDADE METROPOLITANA DA AMAZÔNIA',
		'UFRB-SANTO ANTÔNIO DE JESUS - UNIVERSIDADE FEDERAL DO RECÔNCAVO DA BAHIA UFRB - SANTO ANTÔNIO DE JESUS (BA)',
		'FTESM-SOUZA - ESCOLA DE MEDICINA SOUZA MARQUES FUND. TÉC. - EDUC.',
		'FMP - FACULDADE DE MEDICINA DE PETRÓPOLIS',
		'UEPA-SANTARÉM - UNIVERSIDADE DO ESTADO DO PARÁ - SANTARÉM',
		'FACIMED - FACULDADE DE CIÊNCIAS BIOMÉDICAS DE CACOAL',
		'UNESA-ANGRADOSREIS - UNIVERSIDADE ESTÁCIO DE SÁ - ANGRA DOS REIS',
		'UEFS - UNIVERSIDADE ESTADUAL DE FEIRA DE SANTANA',
		'UFMT-RONDONOPOLIS - UNIVERSIDADE FEDERAL DE MATO GROSSO - RONDONOPOLIS',
		'UFMT-SINOP - UNIVERSIDADE FEDERAL DE MATO GROSSO - SINOP',
		'FIMCA - FACULDADES INTEGRADAS APARÍCIO CARVALHO- PORTO VELHO',
		'UNIR - FUNDAÇÃO UNIVERSIDADE FEDERAL DE RONDÔNIA',
		'UNIVASF-PAULOAFONSO - FUNDAÇÃO UNIVERSIDADE FEDERAL DO VALE DO SÃO FRANCISCO - PAULO AFONSO / BA',
		'UNEMAT - UNIVERSIDADE DO ESTADO DE MATO GROSSO',
		'UERJ - UNIVERSIDADE DO ESTADO DO RIO DE JANEIRO',
		'UNESA-JOÃOUCHOA - UNIVERSIDADE ESTACIO DE SA - JOAO UCHOA',
		'UNIME - UNIÃO METROPOLITANA DE EDUCAÇÃO E CULTURA',
		'UFBA-VITÓRIADACONQUISTA - UNIVERSIDADE FEDERAL DA BAHIA - VITÓRIA DA CONQUISTA',
		'UFOB-BARREIRAS - UNIVERSIDADE FEDERAL DO OESTE DA BAHIA - BARREIRAS / BA',
		'UESC - UNIVERSIDADE ESTADUAL DE SANTA CRUZ',
		'AGES - JACOBINA - FACULDADES AGES - JACOBINA',
		'UERR - UNIVERSIDADE ESTADUAL DE RORAIMA',
		'UESB-VITÓRIADACONQUISTA - UNIVERSIDADE ESTADUAL DO SUDOESTE DA BAHIA / VITÓRIA DA CONQUISTA',
		'FAMESC - FACULDADE METROPOLITANA SÃO CARLOS BJI',
		'UNEB-CABULA - UNIVERSIDADE DO ESTADO DA BAHIA',
		'UESB-JEQUIÉ - UNIVERSIDADE ESTADUAL DO SUDOESTE DA BAHIA / JEQUIÉ',
		'UFSB-TEIXEIRADEFREITAS - UNIVERSIDADE FEDERAL DO SUL DA BAHIA - TEIXEIRA DE FREITAS / BA',
		'UP-CURITIBA - UNIVERSIDADE POSITIVO- CURITIBA',
		'UNISUL-TUBARÃO - UNIVERSIDADE DO SUL DE SANTA CATARINA - TUBARÃO',
		'UNICESUMAR - CENTRO UNIVERSITÁRIO DE MARINGÁ - UNICESUMAR',
		'FAG - FACULDADE ASSIS GURGACZ',
		'UNIVILLE - UNIVERSIDADE DA REGIÃO DE JOINVILLE',
		'UCS - UNIVERSIDADE DE CAXIAS DO SUL',
		'UCPEL - UNIVERSIDADE CATÓLICA DE PELOTAS',
		'UFN-SANTAMARIA - UNIVERSIDADE FRANCISCANA-SANTAMARIA',
		'UNIVATES - CENTRO UNIVERSITÁRIO UNIVATES - UNIVATES - LAJEADO - RS',
		'UNOESC - UNIVERSIDADE DO OESTE DE SANTA CATARINA',
		'ULBRA - UNIVERSIDADE LUTERANA DO BRASIL',
		'FEMPAR - FACULDADE EVANGÉLICA MACKENZIE DO PARANÁ',
		'UFGD - UNIVERSIDADE FEDERAL DE GRANDE DOURADOS',
		'FPP - FACULDADE PEQUENO PRÍNCIPE',
		'PUC-CURITIBA - PONTIFÍCIA UNIVERSIDADE CATÓLICA DO PARANÁ - CURITIBA',
		'UFRGS - UNIVERSIDADE FEDERAL DO RIO GRANDE DO SUL',
		'UNINGÁ - FACULDADE INGÁ',
		'UFFS-CHAPECÓ - UNIVERSIDADE FEDERAL DA FRONTEIRA SUL - CHAPECÓ',
		'UNIVALI - UNIVERSIDADE DO VALE DO ITAJAI',
		'UFPEL - UNIVERSIDADE FEDERAL DE PELOTAS',
		'UPF - UNIVERSIDADE DE PASSO FUNDO',
		'UEM - UNIVERSIDADE ESTADUAL DE MARINGÁ',
		'UNIPAR - UNIVERSIDADE PARANAENSE',
		'PUC-LONDRINA - PONTIFÍCIA UNIVERSIDADE CATÓLICA DO PARANÁ - LONDRINA',
		'UEL - UNIVERSIDADE ESTADUAL DE LONDRINA',
		'UFPR - UNIVERSIDADE FEDERAL DO PARANÁ',
		'UFCSPA - UNIVERSIDADE FEDERAL DE CIÊNCIAS DA SAÚDE DE PORTO ALEGRE',
		'UFSM - UNIVERSIDADE FEDERAL DE SANTA MARIA',
		'UNISINOS - UNIVERSIDADE DO VALE DO RIO DOS SINOS',
		'UFSC - UNIVERSIDADE FEDERAL DE SANTA CATARINA',
		'UFPR-TOLEDO - UNIVERSIDADE FEDERAL DO PARANÁ - UFPR-TOLEDO',
		'IMED - FACULDADE MERIDIONAL',
		'PUC-PORTOALEGRE - PONTÍFICIA UNIVERSIDADE CATÓLICA DO RIO GRANDE DO SUL',
		'UNIOESTE-CASCAVEL - UNIVERSIDADE ESTADUAL DO OESTE DO PARANÁ - CASCAVEL',
		'FURB - UNIVERSIDADE REGIONAL DE BLUMENAU',
		'FURG - FUNDAÇÃO UNIVERSIDADE FEDERAL DO RIO GRANDE',
		'ESTACIO-JARAGUÁDOSUL - Faculdade Estácio - Jaraguá do Sul',
		'UNISUL-PEDRABRANCA - UNIVERSIDADE DO SUL DE SANTA CATARINA - PALHOÇA/PEDRABRANCA',
		'UEPG - UNIVERSIDADE ESTADUAL DE PONTA GROSSA',
		'UFFS-PASSOFUNDO - UNIVERSIDADE FEDERAL DA FRONTEIRA SUL - PASSO FUNDO',
		'UFMS-TRÊSLAGOAS - UNIVERSIDADE FEDERAL DO MATO GROSSO DO SUL - TRÊS LAGOAS',
		'UNESC-CRICIÚMA - UNIVERSIDADE DO EXTREMO SUL CATARINENSE',
		'UEMS - UNIVERSIDADE ESTADUAL DO MATO GROSSO DO SUL',
		'UFMS-CAMPOGRANDE - UNIVERSIDADE FEDERAL DO MATO GROSSO DO SUL - CAMPO GRANDE',
		'URI-ERECHIM - UNIVERSIDADE REGIONAL INTEGRADA DO ALTO URUGUAI E DAS MISSÕES',
		'CR - CENTRO UNIVERSITÁRIO CAMPO REAL',
		'UNISC - UNIVERSIDADE DE SANTA CRUZ DO SUL',
		'UNIPLAC - UNIVERSIDADE DO PLANALTO CATARINENSE',
		'UNILA - UNIVERSIDADE FEDERAL DA INTEGRAÇÃO LATINO-AMERICANA',
		'UNIOESTE-FRANCISCOBELTRÃO - UNIVERSIDADE ESTADUAL DO OESTE DO PARANÁ - FRANCISCO BELTRÃO',
		'INTEGRADO-CAMPOMOURAO - CENTRO UNIVERSITÁRIO GRUPO INTEGRADO',
		'FEEVALE - UNIVERSIDADE FEEVALE',
		'UNIDERP - UNIVERSIDADE P/ DESENV. DO ESTADO E DA REGIÃO DO PANTANAL',
		'UNC - UNIVERSIDADE DO CONTESTADO',
		'UNIPAMPA - UNIVERSIDADE FEDERAL DO PAMPA',
		'UNIARP - UNIVERSIDADE ALTO VALE DO RIO DO PEIXE',
		'UNICENTRO - UNIVERSIDADE ESTADUAL DO CENTRO OESTE',
		'UNIJUI - UNIVERSIDADE REGIONAL DO NOROESTE DO ESTADO DO RIO GRANDE DO SUL',
		'UNIVERSIDADEBRASIL - UNIVERSIDADE BRASIL',
		'UNIMAR - UNIVERSIDADE DE MARÍLIA',
		'UNAERP - UNIVERSIDADE DE RIBEIRÃO PRETO',
		'UNOESTE - UNIVERSIDADE DO OESTE PAULISTA',
		'SLMANDIC - FACULDADE SÃO LEOPOLDO MANDIC',
		'UNILAGO - UNIÃO DA REGIÃO DOS GRANDES LAGOS',
		'FAMERP - FACULDADE DE MEDICINA DE SÃO JOSÉ DO RIO PRETO',
		'FAI - CENTRO UNIVERSITÁRIO DE ADAMANTINA',
		'UNIARA - CENTRO UNIVERSITÁRIO DE ARARAQUARA',
		'UNINOVE-VERGUEIRO - CENTRO UNIVERSITÁRIO NOVE DE JULHO - VERGUEIRO',
		'FAMECA - FACULDADE DE MEDICINA DE CATANDUVA - FUND. PADRE ALBINO',
		'PUC-SOROCABA - PONTIFÍCIA UNIVERSIDADE CATÓLICA - SOROCABA',
		'CUBM - CENTRO UNIVERSITÁRIO BARÃO DE MAUÁ- RIBEIRÃO PRETO - SP',
		'UNICID - UNIVERSIDADE CIDADE DE SÃO PAULO',
		'UNICAMP - UNIVERSIDADE ESTADUAL DE CAMPINAS',
		'UNIMES - UNIVERSIDADE METROPOLITANA DE SANTOS',
		'UNINOVE-OSASCO - UNIVERSIDADE NOVE DE JULHO - OSASCO',
		'USP-RP - FACULDADE DE MEDICINA DE RIBEIRÃO PRETO - UNIVERSIDADE DE SÃO PAULO',
		'SÃOCAMILO - CENTRO UNIVERSITÁRIO SÃO CAMILO',
		'FMJ - FACULDADE DE MEDICINA DE JUNDIAI',
		'ANHEMBI - UNIVERSIDADE ANHEMBI MORUMBI',
		'UNITAU - UNIVERSIDADE DE TAUBATÉ',
		'UNISA - UNIVERSIDADE SANTO AMARO',
		'UNIFAE - CENTRO UNIVERSITÁRIO DAS FACULDADE ASSOCIADAS DE ENSINO - FAE - S.JOÃO DA BOA VISTA / SP- UNIFAE',
		'FMABC - FACULDADE DE MEDICINA DO ABC',
		'UNINOVE-BAURU - UNIVERSIDADE NOVE DE JULHO - BAURU',
		'USCS - UNIVERSIDADE SÃO CAETANO DO SUL',
		'EMESCAM - ESCOLA SUPERIOR DE CIENCIAS DA SANTA CASA DE MISERICORDIA DE VITORIA',
		'FAMEMA - FACULDADE DE MEDICINA DE MARÍLIA',
		'USCS - BELA VISTA - UNIVERSIDADE DE SÃO CAETANO-BELA VISTA',
		'FACERES - FACULDADE CERES',
		'FAM - FACULDADE DAS AMÉRICAS',
		'FACISB - FACULDADE DE CIÊNCIAS DA SAÚDE DE BARRETOS DR. PAULO PRATA',
		'UMC - UNIVERSIDADE DE MOGI DAS CRUZES',
		'PUC-CAMPINAS - PONTÍFICIA UNIVERSIDADE CATÓLICA - CAMPINAS',
		'UNESP - UNIVERSIDADE ESTADUAL PAULISTA JULIO DE MESQUITA FILHO',
		'UNIFESP - UNIVERSIDADE FEDERAL DE SÃO PAULO',
		'UNINOVE-GRU - UNIVERSIDADE NOVE DE JULHO - GRU',
		'FCMSCSP - FACULDADE DE CIÊNCIAS MÉDICAS DA STA. CASA DE SÃO PAULO',
		'HUMANITAS - FACULDADE DE CIÊNCIAS MÉDICAS DE SÃO JOSÉ DOS CAMPOS',
		'FEMA - FUNDAÇÃO EDUCACIONAL DO MUNICÍPIO DE ASSIS',
		'USP-BAURU - Faculdade de Medicina da Universidade de São Paulo - Bauru',
		'CLARETIANORC - CENTRO UNIVERSITÁRIO DE RIO CLARO',
		'UNIFEV - CENTRO UNIVERSITARIO DE VOTUPORANGA',
		'ESTACIO-RP - CENTRO UNIVERSITÁRIO UNISEB - RIBEIRÃO PRETO / SP - UNISEB',
		'UNINOVE-MAUÁ - UNIVERSIDADE NOVE DE JULHO - MAUÁ',
		'UNILUS - CENTRO UNIVERSITÁRIO LUSÍADA',
		'USF - UNIVERSIDADE SÃO FRANCISCO',
		'UNIFACEF - CENTRO UNIVERSITÁRIO DE FRANCA - FRANCA - SP - UNI - FACEF',
		'UNIFADRA - FACULDADE DE DRACENA',
		'FASM - FACULDADE SANTA MARCELINA',
		'UVV - UNIVERSIDADE DE VILA VELHA',
		'UFES - UNIVERSIDADE FEDERAL DO ESPÍRITO SANTO',
		'SLMANDIC-ARARAS - FACULDADE DE MEDICINA E ODONTOLOGIA SÃO LEOPOLDO MANDIC',
		'FICSAE - FACULDADE ISRAELITA DE CIÊNCIAS DA SAÚDE ALBERT EINSTEIN',
		'MULTIVIX-VITÓRIA - FACULDADE MULTIVIX VITÓRIA',
		'UNIFRAN - UNIVERSIDADE DE FRANCA',
		'UNINOVE-SÃOBERNADODOCAMPO - UNIVERSIDADE NOVE DE JULHO - SÃO BERNADO DO CAMPO',
		'UNOESTE-GUARUJÁ - UNIVERSIDADE DO OESTE PAULISTA - GUARUJÁ',
		'USP-SP - FACULDADE DE MEDICINA DA UNIVERSIDADE DE SÃO PAULO',
		'ANHEMBI-SÃOJOSÉDOSCAMPOS - UNIVERSIDADE ANHEMBI MORUMBI - SÃO JOSÉ DOS CAMPOS',
		'UFSCAR - UNIVERSIDADE FEDERAL DE SÃO CARLOS',
		'ANHEMBI-PIRACICABA - UNIVERSIDADE ANHEMBI MORUMBI - PIRACICABA',
		'UNAERP-GUARUJÁ - UNIVERSIDADE DE RIBEIRÃO PRETO - GUARUJÁ',
		'UNISALESIANO - CENTRO UNIVERSITÁRIO CATÓLICO SALESIANO AUXILIUM ARAÇATUBA',
		'UNIFUNEC - FACULDADE INTEGRADA DE SANTA FÉ DO SUL',
		'FUNEPE - FUNDAÇÃO EDUCACIONAL DE PENÁPOLIS',
		'UNOESTE-JAÚ - UNIVERSIDADE DO OESTE PAULISTA - JAÚ',
		'SÃOJUDAS-CUBATÃO - UNIVERSIDADE SÃO JUDAS TADEU - CUBATÃO',
		'UNIATENAS-PARACATU - FACULDADE ATENAS-PARACATU',
		'UNIFENAS-ALFENAS - UNIVERSIDADE JOSÉ DO ROSÁRIO VELLANO - ALFENAS',
		'UNIEVANGÉLICA - UNIVERSIDADE EVANGÉLICA DE ANÁPOLIS',
		'UFMG - UNIVERSIDADE FEDERAL DE MINAS GERAIS',
		'SUPREMA - FACULDADE CIÊNCIAS MÉDICAS E DA SAÚDE DE JUIZ DE FORA',
		'IMEPAC - INSTITUTO MASTER DE ENSINO PRESIDENTE ANTÔNIO CARLOS',
		'UNIPAC-JF - UNIVERSIDADE PRESIDENTE ANTÔNIO CARLOS - JUIZ DE FORA',
		'UNIBH - CENTRO UNIVERSITÁRIO DE BELO HORIZONTE',
		'UFOP - UNIVERSIDADE FEDERAL DE OURO PRETO',
		'UCB - UNIVERSIDADE CATÓLICA DE BRASÍLIA',
		'UNIRV-RIOVERDE - UNIVERSIDADE DE RIO VERDE - CAMPUS RIO VERDE',
		'UNIVAS-POUSOALEGRE - UNIVERSIDADE DO VALE DO SAPUCAÍ - POUSO ALEGRE',
		'UFU - UNIVERSIDADE FEDERAL DE UBERLÂNDIA',
		'FAME-FUNJOB - FACULDADE DE MEDICINA DE BARBACENA',
		'PUC-BETIM - PONTIFÍCIA UNIVERSIDADE CATÓLICA DE MINAS GERAIS - BETIM',
		'UNIPAM - CENTRO UNIVERSITÁRIO DE PATOS DE MINAS',
		'UFG-GOIÂNIA - UNIVERSIDADE FEDERAL DE GOIÁS / GOIÂNIA',
		'UFJF - UNIVERSIDADE FEDERAL DE JUIZ DE FORA',
		'CEUB - CENTRO UNIVERSITÁRIO DE BRASÍLIA',
		'UNIRV-GOIANÉSIA - UNIVERSIDADE DE RIO VERDE - CAMPUS GOIANESIA',
		'UNIATENAS-PASSOS - FACULDADE ATENAS-PASSOS',
		'UFJFGV - UNIVERSIDADE FEDERAL DE JUIZ DE FORA - GOVERNADOR VALADARES',
		'UFVJM-DIAMANTINA - UNIVERSIDADE FEDERAL DOS VALES DO JEQUITINHONHA E MUCURI - DIAMANTINA',
		'UNIFACIG - CENTRO UNIVERSITÁRIO UNIFACIG',
		'FAMP - FACULDADE MORGANA POTRICH',
		'PUC-POÇOSDECALDAS - PONTIFÍCIA UNIVERSIDADE CATÓLICA DE MINAS GERAIS - POÇOS DE CALDAS',
		'UNIUBE - UNIVERSIDADE DE UBERABA',
		'UNIMONTES - UNIVERSIDADE ESTADUAL DE MONTES CLAROS',
		'UFSJ-SÃOJOÃODELREI - UNIVERSIDADE FEDERAL DE SÂO JOÃO DEL REI - SÂO JOÃO DEL REI',
		'UFTM - UNIVERSIDADE FEDERAL DO TRIÂNGULO MINEIRO',
		'UNIFIMES - CENTRO UNIVERSITÁRIO DE MINEIROS',
		'ESCS - ESCOLA SUPERIOR DE CIÊNCIAS DA SAÚDE',
		'UNB - UNIVERSIDADE DE BRASÍLIA',
		'UFSJ-DIVINÓPOLIS - UNIVERSIDADE FEDERAL DE SÃO JOÃO DEL REI - DIVINÓPOLIS',
		'UFVJM-TEÓFILOOTONI - UNIVERSIDADE FEDERAL DOS VALES DO JEQUITINHONHA E MUCURI - TEÓFILO OTONI',
		'UNIFAN - FACULDADE ALFREDO NASSER - APARECIDA DE GOIANIA - GO',
		'FCMMG - FACULDADE CIÊNCIAS MÉDICAS MG - FUND. EDU. LUCAS MACHADO',
		'FASEH - FACULDADE DA SAUDE E ECOLOGIA HUMANA',
		'FADIP - FACULDADE DINÂMICA DO VALE DO PIRANGA',
		'UIT - UNIVERSIDADE DE ITAÚNA',
		'UNIRV-APARECIDA - UNIVERSIDADE DE RIO VERDE - CAMPUS APARECIDA DE GOIANIA',
		'UNIFAL - UNIVERSIDADE FEDERAL DE ALFENAS UNIFAL ALFENAS MG',
		'UNEC-CARATINGA - CENTRO UNIVERSITÁRIO DE CARATINGA',
		'ICS-FUNORTE - FACULDADES INTEGRADAS DO NORTE DE MINAS',
		'UFLA - UNIVERSIDADE FEDERAL DE LAVRAS - MG - UFLA',
		'PUC-GOIÂNIA - PONTIFICIA UNIVERSIDADE CATÓLICA - GOIÂNIA',
		'PUC-CONTAGEM - PONTIFÍCIA UNIVERSIDADE CATÓLICA DE MINAS GERAIS - CONTAGEM',
		'UFG-JATAÍ - UNIVERSIDADE FEDERAL DE GOIÁS - JATAÍ',
		'UNICERRADO - CENTRO UNIVERSITÁRIO DE GOIATUBA',
		'UNIATENAS SETE LAGOAS - FACULDADE ATENAS - SETE LAGOAS',
		'FAGOC - FACULDADE GOVERNADOR OZANAM COELHO',
		'UEMG - UNIVERSIDADE DO ESTADO DE MINAS GERAIS',
		'UNIFENAS-BELOHORIZONTE - UNIVERSIDADE JOSE DO ROSARIO VELLANO - BELO HORIZONTE',
		'UFV - FUNDAÇÃO UNIVERSIDADE FEDERAL DE VIÇOSA',
		'UNIVALE - UNIVERSIDADE VALE DO RIO DOCE',
		'UNIVÉRTIX - MATIPÓ - FACULDADE VÉRTICE - UNIVÉRTIX',
		'FEX-UDABOL-LA PAZ - UNIVERSIDAD AQUINO DE BOLÍVIA-LA PAZ',
		'FEX-UNINTER-CIUDAD DEL ESTE - UNIVERSIDAD INTERNACIONAL TRES FRONTERAS - CIUDAD DEL ESTE',
		'FEX-UCEBOL - UNIVERSIDAD CRISTIANA DE BOLIVIA - SANTA CRUZ',
		'FEX-UMS-CDE - UNIVERSIDAD MARIA SERRANA - CIUDAD DEL ESTE',
		'FEX-UPE-FRANCO - UNIVERSIDAD PRIVADA DEL ESTE - FRANCO',
		'FEX-SUDA - UNIVERSIDAD SUDAMERICANA',
		'FEX-UNINTER-PEDRO JUAN - UNIVERSIDAD INTERNACIONAL TRES FRONTERAS - PEDRO JUAN',
		'FEX-UDABOL-SANTA CRUZ - UNIVERSIDAD DE AQUINO BOLIVIA-SANTA CRUZ',
		'FEX-UPAP-CIUDAD DEL ESTE - UNIVERSIDAD POLITÉCNICA Y ARTISTICA DEL PARAGUAY - CIUDAD DEL ESTE',
		'FEX-UPAP-PEDRO JUAN - UNIVERSIDAD POLITÉCNICA Y ARTISTICA DEL PARAGUAY - PEDRO JUAN',
		'FEX-UPE-CDE - UNIVERSIDAD PRIVADA DEL ESTE-CIUDAD DEL ESTE',
		'FEX-UCB - BOLIVIA - UNIVERSIDAD CATÓLICA BOLIVIANA',
		'KSMU - KURSK STATE MEDICAL UNIVERSITY',
		'FEX-UNINORTE-PARAGUAY - UNIVERSIDAD DEL NORTE PARAGUAY - PEDRO JUAN CABALLERO',
		'FEX-UMAX - UNIVERSIDAD MARIA AUXILIADORA',
		'FEX-UASS - UNIVERSIDAD AUTÓNOMA SAN SEBASTIÁN',
		'FEX-UPAL - UNIVERSIDAD PRIVADA ABERTA LATINOAMERICANA',
		'FEX-UNITEPC - UNIVERSIDADE TÉCNICA PRIVADA COSMOS - COCHABAMBA',
		'FEX-BARCELÓ - BUENOS AIRES - FUNDACIÓN HECTOR A. BARCELÓ - BUENOS AIRES',
		'FEX-UAP-BOLIVIA - UNIVERSIDAD AMAZÔNICA DE PANDO',
		'FEX-UBA - UNIVERSIDAD DE BUENOS AIRES',
		'FEX-UP-PEDRO JUAN - UNIVERSIDAD DEL PACIFICO - PEDRO JUAN',
		'FEX-UASS-ASUNCIÓN - UNIVERSIDAD AUTONOMA SAN SEBASTIAN - ASUNCIÓN',
		'FEX-UNIDA-CDE - UNIVERSIDAD DE LA INTEGRACIÓN DE LAS AMÉRICAS-CIUDAD DEL ESTE',
		'FEX-UMS-ASUNCIÓN - UNIVERSIDAD MARIA SERRANA - ASUNCIÓN',
		'FEX-UNR - UNIVERSIDAD NACIONAL DE ROSARIO',
		'FEX-ECOLÓGICA - UNIVERSIDAD NACIONAL ECOLÓGICA',
		'FEX-UPG - PEDRO JUAN - UNIVERSIDAD PRIVADA DEL GUAIRÁ - PEDRO JUAN CABALLERO',
		'FEX-UCP-ASUNCIÓN - UNIVERSIDADE CENTRAL DO PARAGUAY-ASUNCIÓN',
		'FEX-UAI-BUENOS AIRES - UNIVERSIDAD ABIERTA INTERAMERICANA-BUENOS AIRES',
		'FEX-UNIFRANZ-SANTA CRUZ - UNIVERSIDAD PRIVADA FRANZ TAMAYO-SANTA CRUZ',
		'FEX-UNINORTE-ASUNCÍON - UNIVERSIDAD DEL NORTE PARAGUAY-ASUNCÍON',
		'FEX-UNIFRANZ-LA PAZ - UNIVERSIDAD PRIVADA FRANZ TAMAYO-LA PAZ',
		'FEX-UNIMORÓN - UNIVERSIDADE DE MORÓN',
		'FEX-UMSS - UNIVERSIDADE MAYOR DE SAN SIMON',
		'BARCELÓ - LA RIOJA - FUNDACIÓN HECTOR A. BARCELÓ - LA RIOJA',
		'FEX-UCA-BS - PONTIFICIA UNIV. CATÓLICA SANTA MARÍA DE LOS BUENOS AIRES',
		'FEX-UAP-ARGENTINA - UNIVERSIDAD ADVENTISTA DEL PLATA',
		'FEX-USAL - UNIVERSIDAD DEL SALVADOR - BUENOS AIRES',
		'FEX-UNE - UNIVERSIDAD NACIONAL DEL ESTE',
		'FEX-UNSLP - UNIVERSIDAD NUESTRA SENORA DE LA PAZ',
		'FEX-UAI-ROSÁRIO - UNIVERSIDAD ABIERTA INTERAMERICANA-ROSÁRIO',
		'FEX-UDABOL-COCHABAMBA - UNIVERSIDAD AQUINO DE BOLÍVIA-COCHABAMBA',
		'FEX-UAA - UNIVERSIDAD AUTONOMA DE ASUNCIÓN',
		'FEX-UNIDA-ASUNCÍON - UNIVERSIDAD DE LA INTEGRACIÓN DE LAS AMÉRICAS-ASUNCÍON',
		'FEX-UNIVALLE-COCHABAMBA - UNIVERSIDAD DEL VALLE - COCHABAMBA',
		'FEX-UNIVALLE-LA PAZ - UNIVERSIDAD DEL VALLE-LA PAZ',
		'UNC - ARGENTINA - UNIVERSIDAD NACIONAL DE CÓRDOBA',
		'FEX-UPG - CIUDAD DEL ESTE - UNIVERSIDAD PRIVADA DEL GUAIRÁ - CIUDAD DEL ESTE',
		'FEX-UNIFRANZ-COCHABAMBA - UNIVERSIDAD PRIVADA FRANZ TAMAYO-COCHABAMBA',
		'FEX-UAGRM - UNIVERSIDADE AUTÓNOMA GABRIEL RENÉ MORENO',
		'BARCELÓ - SANTO TOMÉ - FUNDACIÓN HECTOR A. BARCELÓ - SANTO TOMÉ',
		'FEX-UNIR - INSTITUTO UNIVERSITÁRIO HOSPITAL ITALIANO',
		'FEX-UP-ASUNCIÓN - UNIVERSIDAD DEL PACIFICO - ASUNCIÓN',
		'FEX-UNA - UNIVERSIDAD NACIONAL DE ASSUNÇÃO - PARAGUAY',
		'FEX-UNLP - UNIVERSIDAD NACIONAL DE LA PLATA',
		'UNNE - UNIVERSIDAD NACIONAL DEL NORDESTE',
		'FEX-SAINT PAUL - UNIVERSIDAD SAINT PAUL',
		'FEX-UNISAL- CDE - UNIVERSIDAD SAN LORENZO',
		'FEX-MAIMÓNIDES - UNIVERSIDADE MAIMÓNIDES',
	];

	const [filteredFaculdades, setFilteredFaculdades] = useState(faculdades);
	const [open, setOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);

	const handleSearchChange = (e) => {
		const value = e.target.value.toLowerCase();
		setSearchValue(value);
		setFilteredFaculdades(faculdades.filter((faculdade) => faculdade.toLowerCase().includes(value)));
		setOpen(value.length > 0);
		setAnchorEl(e.currentTarget);
	};

	const handleSelectFaculdade = (faculdade) => {
		if (faculdade) {
			setSelectedFaculdade(faculdade);
			setSearchValue(faculdade);

			onHandleAnswer(step, faculdade);
			setOpen(false);
		} else {
		}
	};

	const handleClickAway = () => {
		setOpen(false);
	};

	return (
		<Grid
			container
			direction="column"
			spacing={2}
			style={{ padding: '1rem', margin: '0rem' }}
		>
			<TextField
				variant="outlined"
				placeholder="Buscar faculdade"
				value={searchValue}
				onChange={handleSearchChange}
				fullWidth
				style={{ backgroundColor: '#ffffff10', color: '#fff', borderRadius: '8px' }}
				InputProps={{
					style: { color: '#fff' },

					inputProps: {
						style: {
							color: '#fff',
						},
					},
				}}
				InputLabelProps={{
					style: { color: '#fff' },
				}}
			/>
			<ClickAwayListener onClickAway={handleClickAway}>
				<Popper
					open={open}
					anchorEl={anchorEl}
					placement="bottom-start"
					style={{ zIndex: 1 }}
				>
					<List
						style={{
							maxHeight: '400px',
							maxWidth: '320px',
							overflowY: 'auto',
							backgroundColor: '#3B3B3B',
							border: '1px solid #303030',
						}}
					>
						{filteredFaculdades.map((faculdade, index) => (
							<ListItem
								key={index}
								onClick={() => handleSelectFaculdade(faculdade)}
								sx={{
									backgroundColor: '#3B3B3B',
									color: '#CCCCCC',
									transition: 'background-color 0.3s',
									'&:hover': {
										backgroundColor: '#656565',
									},
								}}
							>
								<ListItemText
									primaryTypographyProps={{ fontSize: '12px' }}
									primary={faculdade?.toUpperCase()}
								/>
							</ListItem>
						))}
					</List>
				</Popper>
			</ClickAwayListener>
		</Grid>
	);
};

const QuestionSlide = ({ classes, title, subtitle, children, step, onHandleAnswer, questions, index }) => {
	const theme = useTheme();
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesXl = useMediaQuery(theme.breakpoints.down('xl'));
	const matches2Xl = useMediaQuery(theme.breakpoints.down('2xl'));
	const [resposta, setResposta] = useState('');
	const navigate = useNavigate();

	const hasValidAnswer = () => {
		if (Array.isArray(questions) && questions[index]?.answer) {
			return questions[index].answer.trim() !== ''; // Verifica se a resposta não está vazia
		}
		return false;
	};

	const cardRef = useRef(null);

	const handleAdvance = () => {
		// e.preventDefault();
		onHandleAnswer(step, resposta);
		if (matchesMobile) {
			if (cardRef.current) {
				cardRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
				window.scrollBy({ top: -200, left: 0, behavior: 'smooth' });
			}
		}
	};

	return step === 7 ? (
		<Grid
			ref={cardRef}
			container
			direction="column"
			justifyContent="center"
			alignItems="center"
			alignSelf={'center'}
			style={{
				margin: '0 auto',
				bottom: '-25%',
				borderRadius: '20px',
				paddingBottom: '2rem',
				backgroundColor: '#303030cc',
			}}
			width={matchesMobile ? 'auto' : '350px'}
		>
			<Grid
				item
				container
				direction="column"
				className={classes.gridTitle}
				justifyContent="center"
			>
				<Typography
					align="left"
					color="#fff"
					className={`${classes.titleInformationalCardPrimary} roboto-condensed`}
				>
					{title}
				</Typography>
				<Typography
					align="left"
					color="#fff"
					className={`${classes.subtitleInformationalCard} roboto-condensed`}
				>
					{subtitle}
				</Typography>
			</Grid>
			<Grid
				item
				xs={6}
				container
				justifyContent="center"
				alignContent="center"
				style={{
					flexBasis: 'auto',
					marginTop: '0.5rem',
				}}
			>
				<Grid
					item
					xs={8}
					md={10}
					container
					justifyContent="center"
					className={`${classes.gridBtn} residencia`}
					marginBottom={matches2Xl ? '4rem' : '2rem'}
				>
					<DefaultButton
						variant="outlined"
						color="secondary"
						className={`${classes.btn} btn btn-primary`}
						onClick={() => {
							window.location.reload();
						}}
					>
						Concluir
					</DefaultButton>
				</Grid>
			</Grid>
		</Grid>
	) : (
		<Grid
			ref={cardRef}
			container
			direction="column"
			justifyContent="center"
			alignItems="center"
			alignSelf={'center'}
			style={{
				margin: '0 auto',
				// bottom:
				// 	step === 0
				// 		? '-10%'
				// 		: step === 1
				// 		? '-10%'
				// 		: step === 2
				// 		? '-10%'
				// 		: step === 3
				// 		? '-10%'
				// 		: step === 4
				// 		? '-10%'
				// 		: '-10%',
				borderRadius: '20px',
				// paddingBottom: '2rem',
				backgroundColor: '#303030cc',
			}}
			width={matchesMobile ? 'auto' : '398px'}
		>
			<Grid
				item
				container
				direction="column"
				className={classes.gridTitle}
				justifyContent="center"
			>
				<Typography
					align="left"
					color="#fff"
					className={`${
						step === 0 ? classes.titleInformationalCardPrimary : classes.titleInformationalCard
					} roboto-condensed`}
				>
					{title}
				</Typography>
				{children}
			</Grid>
			<Grid
				item
				xs={6}
				container
				justifyContent="center"
				alignContent="center"
				style={{
					flexBasis: 'auto',
					marginTop: '0.5rem',
				}}
			>
				<Grid
					item
					xs={8}
					md={10}
					container
					justifyContent="center"
					className={`${classes.gridBtn} residencia`}
					marginBottom={matches2Xl ? '4rem' : '2rem'}
					marginTop={'1.5rem'}
				>
					<DefaultButton
						variant="outlined"
						color="secondary"
						className={`${classes.btn} btn btn-primary`}
						onClick={() => handleAdvance()}
					>
						Avançar
					</DefaultButton>
				</Grid>
			</Grid>
		</Grid>
	);
};

const AssesmentCard = ({ classes }) => {
	const theme = useTheme();
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const [step, setStep] = useState(1);
	const refSplide = useRef(Splide);
	const [idRetornado, setIdRetornado] = useState('');
	const [nome, setNome] = useState('');
	const [email, setEmail] = useState('');
	const [telefone, setTelefone] = useState(0);

	const [selectedFaculdade, setSelectedFaculdade] = useState('');

	const navigate = useNavigate();

	const [questions, setQuestions] = useState([
		{
			cod: 'Q0',
			options: [faculdades],
			answer: '',
		},
		{
			cod: 'Q1',
			options: [
				'2025 - 1º semestre',
				' 2025 - 2º semestre',
				'2026 - 1º semestre',
				'2026 - 2º semestre',
				'2027 - 1º semestre',
				'2027 - 2º semestre',
				'Após 2028',
			],
			answer: '',
		},
		{
			cod: 'Q2',
			options: [
				'Sim, não percebo lacunas na minha formação.',
				'Ainda não, mas acredito que até a formatura terei.',
				'Não, e sinto que minha formação precisa ser complementada.',
			],
			answer: '',
		},
		{
			cod: 'Q3',
			options: [
				'Sim, farei prova após finalizar a graduação.',
				'Sim, mas pretendo trabalhar um pouco antes e farei prova no ano seguinte.',
				'Não, farei uma pós-graduação.',
				'Ainda não decidi.',
			],
			answer: '',
			textInput: '',
		},
		{
			cod: 'Q4',
			options: [
				'Fazer parte do time hardwork ajudaria a complementar a renda.',
				'Ter acesso gratuito aos cursos Hardwork.',
				'Melhorar a minha formação médica global e poder contribuir na formação de outras pessoas.',
			],
			answer: '',
		},
	]);

	const handleAnswer = (currentStep) => {
		// Validação para o passo atual
		if (currentStep === 0) {
			// Validação dos campos de nome, email e telefone
			let valid = true;

			if (!nome.trim()) {
				valid = false;
			}
			if (!email.trim()) {
				valid = false;
			} else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
				valid = false;
			}
			if (!telefone.trim()) {
				valid = false;
			} else if (!/^\([1-9]{2}\) [9]{1}[0-9]{4}-[0-9]{4}$/.test(telefone)) {
				valid = false;
			}

			if (!valid) {
				return; // Interrompe o fluxo se houver erro
			}

			// Avança para o próximo passo sem enviar dados ainda
			avancarPasso(currentStep);
			return;
		} else if (currentStep === 1) {
			// Validação do passo 1 (primeira resposta)
			if (!selectedFaculdade || selectedFaculdade.trim() === '') {
				return; // Impede avanço
			}

			// Obtém a primeira resposta da lista de perguntas
			const primeiraResposta = questions.length > 0 ? questions[0] : null;

			// Verifica se a resposta está preenchida
			if (!primeiraResposta || !primeiraResposta.answer || primeiraResposta.answer.trim() === '') {
				return; // Impede avanço
			}

			// Constrói o payload do POST com apenas a primeira resposta
			const pesquisaResidencia = {
				id_pesquisa: 1002,
				id_aluno: '',
				nome: nome,
				email: email,
				telefone: telefone,
				respostas: [
					{
						pergunta: `PERG_${primeiraResposta.cod}`,
						resposta: primeiraResposta.answer,
					},
				],
			};
			// POST: Envia os dados coletados no formulário + primeira resposta
			trackPromise(Api.post('Pesquisas', pesquisaResidencia))
				.then((response) => {
					setIdRetornado(response.data?.id || response); // Armazena o ID retornado
					avancarPasso(currentStep); // Avança para o próximo passo
				})
				.catch((error) => {});

			return; // Finaliza após o POST
		} else if (currentStep >= 2) {
			// Validação para passos subsequentes
			const questionIndex = currentStep - 1;

			// Determina a resposta correta com base no código da pergunta
			const resposta = questions[questionIndex]?.answer;
			const textInput = questions[questionIndex]?.textInput;

			if (!resposta || resposta.trim() === '') {
				return; // Impede avanço
			}
			const pesquisaResidenciaAtualizada = {
				id_pesquisa: 1002,
				cod_respondente: idRetornado,
				respostas: [
					{
						pergunta: `PERG_${questions[questionIndex]?.cod}`,
						resposta: questions[questionIndex]?.cod === 'Q3' ? `${resposta} - ${textInput}` : resposta,
					},
				],
			};

			// PUT: Atualiza os dados
			trackPromise(Api.put('Pesquisas', pesquisaResidenciaAtualizada))
				.then(() => {
					avancarPasso(currentStep); // Avança para o próximo passo
				})
				.catch((error) => {});

			return; // Finaliza após o PUT
		}

		// Avança para o próximo passo
		avancarPasso(currentStep);
	};

	// Função para avançar o passo
	const avancarPasso = (currentStep) => {
		const nextStep = currentStep + 2;
		setStep(nextStep);
		setTimeout(() => refSplide.current.go(nextStep), 250);
	};

	const responder = (questionIndex, answerValue) => {
		const updatedQuestions = [...questions];
		updatedQuestions[questionIndex].answer = answerValue;
		setQuestions(updatedQuestions);
	};

	const handleSteps = (step) => {
		setStep(step);
		setTimeout(() => refSplide.current.go(step), 250);
	};

	const handleTextInputChange = (questionIndex, value) => {
		setQuestions((prevQuestions) => {
			const updatedQuestions = [...prevQuestions];
			updatedQuestions[questionIndex].textInput = value;
			return updatedQuestions;
		});
	};

	const reference = useRef(null);

	const executeScroll = () => reference.current.scrollIntoView({ behavior: 'smooth' });

	return (
		<Grid
			container
			direction={'column'}
			justifyContent={'center'}
			className={`${classes.paper} `}
			padding={matchesMobile ? '2rem' : 0}
		>
			<Splide
				className={classes.splideMainContainer}
				options={{
					arrows: false,
					height:
						step === 0
							? '25rem'
							: step === 1
							? '32rem'
							: step === 2
							? '25rem'
							: step === 3
							? '40rem'
							: step === 4
							? '30rem'
							: step === 5
							? '35rem'
							: step === 6
							? '30rem'
							: '35rem',
					width: '100%',
					drag: true,
					speed: 900,
					pagination: true,
				}}
				ref={refSplide}
			>
				{step > 0 && (
					<SplideSlide>
						<QuestionSlide
							title="Entre para a legião!"
							classes={classes}
							step={0}
							onHandleAnswer={handleAnswer}
							questions={questions}
							index={0}
							ref={reference}
						>
							<Grid
								item
								container
								style={{ overflowY: 'auto', maxHeight: '315px' }}
							>
								<SplideSlide>
									<FormSlide
										classes={classes}
										onHandleAnswer={(step, resposta) => handleAnswer(step, resposta)}
										setNome={setNome}
										setEmail={setEmail}
										setTelefone={setTelefone}
									/>
								</SplideSlide>
							</Grid>
						</QuestionSlide>
					</SplideSlide>
				)}
				{step > 1 && (
					<SplideSlide>
						<QuestionSlide
							title="Qual a tua faculdade?"
							classes={classes}
							step={1}
							onHandleAnswer={handleAnswer}
							questions={questions}
							index={1}
						>
							<Grid
								item
								container
								style={{ overflowY: 'auto', maxHeight: '315px' }}
							>
								<QuestionSlideWithSearch
									step={0}
									classes={classes}
									onHandleAnswer={responder}
									selectedFaculdade={selectedFaculdade}
									setSelectedFaculdade={setSelectedFaculdade}
								/>
							</Grid>
						</QuestionSlide>
					</SplideSlide>
				)}
				{step > 2 && (
					<SplideSlide>
						<QuestionSlide
							title={'Quando finalizará a gradução?'}
							classes={classes}
							step={2}
							onHandleAnswer={handleAnswer}
							questions={questions}
							index={2}
							paddingY={'2rem'}
						>
							<Grid
								container
								rowGap={1}
							>
								<Grid container>
									<Grid
										container
										justifyContent="center"
										alignItems="center"
									>
										<Grid
											item
											xs={9.5}
										>
											<RadioGroup
												aria-labelledby="demo-radio-buttons-group-label"
												name="radio-buttons-group"
												style={{
													rowGap: '0.5rem',
													overflowY: 'auto',
													whiteSpace: 'nowrap',
													backgroundColor: '##FFFFFF1A',
													width: 'full',
												}}
												value={questions[1].answer}
												onChange={(event) => responder(1, event.target.value)}
											>
												{questions[1].options.map((item, index) => {
													return (
														<FormControlLabel
															lineHeight={8}
															key={index}
															value={item}
															control={
																<Radio
																	color="secondary"
																	sx={{
																		color: '#ffffff',
																	}}
																/>
															}
															label={
																<span
																	lineHeight={'1px'}
																	className={`${classes.spanStyle} Manrope`}
																>
																	{item}
																</span>
															}
															onChange={() => responder(1, item)}
															className={classes.radioBtnRoot}
														/>
													);
												})}
											</RadioGroup>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</QuestionSlide>
					</SplideSlide>
				)}
				{step > 3 && (
					<SplideSlide>
						<QuestionSlide
							title="Sente segurança com a formação dada pela faculdade?"
							classes={classes}
							step={3}
							onHandleAnswer={handleAnswer}
							questions={questions}
							index={3}
						>
							<Grid className={classes.gridFormScroll}>
								<RadioGroup
									aria-labelledby="demo-radio-buttons-group-label"
									name="radio-buttons-group"
									value={questions[2].answer}
									style={{ rowGap: '0.5rem' }}
								>
									{questions[2].options.map((item, index) => {
										return (
											<FormControlLabel
												key={index}
												value={item}
												control={
													<Radio
														color="secondary"
														sx={{
															color: '#ffffff',
														}}
													/>
												}
												label={
													<span
														// style={{ lineHeight: '10px' }}
														className={`${classes.spanStyle} Manrope`}
													>
														{item}
													</span>
												}
												onChange={() => responder(2, item)}
												className={classes.radioBtnRoot}
											/>
										);
									})}
								</RadioGroup>
							</Grid>
						</QuestionSlide>
					</SplideSlide>
				)}
				{step > 4 && (
					<SplideSlide>
						<QuestionSlide
							title="Pretende fazer residência médica?"
							classes={classes}
							step={4}
							onHandleAnswer={handleAnswer}
							questions={questions}
							index={4}
						>
							<Grid className={classes.gridFormScroll}>
								<RadioGroup
									aria-labelledby="demo-radio-buttons-group-label"
									name="radio-buttons-group"
									value={questions[3].answer}
									style={{ rowGap: '0.5rem' }}
								>
									{questions[3].options.map((item, index) => (
										<React.Fragment key={index}>
											<FormControlLabel
												value={item}
												control={
													<Radio
														color="secondary"
														sx={{
															color: '#ffffff',
														}}
													/>
												}
												label={<span className={`${classes.spanStyle} Manrope`}>{item}</span>}
												onChange={() => responder(3, item)}
												className={classes.radioBtnRoot}
											/>

											{index === 0 && questions[3].answer === questions[3].options[0] && (
												<Grid
													container
													justifyContent="center"
													alignItems="center"
													justifySelf="center"
												>
													<Grid
														item
														xl={11}
														lg={11}
														md={11}
														justifyContent={'center'}
														alignItems={'center'}
														justifySelf={'center'}
														backgroundColor={'#666666E5'}
														padding={'0.5rem'}
														style={{
															fontFamily: 'Monrope',
															fontSize: '13px',
															color: '#EBEBEB',
															borderRadius: '4px',
														}}
													>
														Qual a especialidade e serviço pretendido?
														<textarea
															className={classes.textAreaStyle}
															onChange={(e) => handleTextInputChange(3, e.target.value)}
														/>
													</Grid>
												</Grid>
											)}
											{index === 1 && questions[3].answer === questions[3].options[1] && (
												<Grid
													container
													justifyContent="center"
													alignItems="center"
													justifySelf="center"
												>
													<Grid
														item
														xl={11}
														lg={11}
														md={11}
														justifyContent={'center'}
														alignItems={'center'}
														justifySelf={'center'}
														backgroundColor={'#666666E5'}
														padding={'0.5rem'}
														style={{
															fontFamily: 'Monrope',
															fontSize: '13px',
															color: '#EBEBEB',
															borderRadius: '4px',
														}}
													>
														Qual a especialidade e serviço pretendido?
														<textarea
															className={classes.textAreaStyle}
															onChange={(e) => handleTextInputChange(3, e.target.value)}
														/>
													</Grid>
												</Grid>
											)}
										</React.Fragment>
									))}
								</RadioGroup>
							</Grid>
						</QuestionSlide>
					</SplideSlide>
				)}

				{step > 5 && (
					<SplideSlide>
						<QuestionSlide
							title="Tu estás a um passo de viver a Medicina Hardwork. Qual o maior benefício que espera disso?"
							classes={classes}
							step={5}
							onHandleAnswer={handleAnswer}
							questions={questions}
							index={5}
						>
							<Grid className={classes.gridFormScroll}>
								<RadioGroup
									aria-labelledby="demo-radio-buttons-group-label"
									name="radio-buttons-group"
									value={questions[4].answer} // Define o valor selecionado
									style={{ rowGap: '0.5rem' }}
								>
									{questions[4].options.map((item, index) => {
										return (
											<FormControlLabel
												key={index}
												value={item}
												control={
													<Radio
														color="secondary"
														sx={{
															color: '#ffffff',
														}}
													/>
												}
												label={<span className={`${classes.spanStyle} Manrope`}>{item}</span>}
												onChange={() => responder(4, item)}
												className={classes.radioBtnRoot}
											/>
										);
									})}
								</RadioGroup>
							</Grid>
						</QuestionSlide>
					</SplideSlide>
				)}

				{/* {step > 5 && (
					<SplideSlide>
						<QuestionSlide
							title="Caso queira, compartilhe com a gente a tua experiência com o Hardwork até agora."
							classes={classes}
							step={6}
							onHandleAnswer={handleAnswer}
							questions={questions}
							index={7}
						>
							<Grid style={{ overflowY: 'auto', maxHeight: '315px' }}>
								<TextField
									fullWidth
									type="string"
									placeholder="Escreva sua mensagem..."
									multiline
									rows={8}
									InputProps={{
										className: classes.form,
									}}
									InputLabelProps={{
										shrink: true,
									}}
									onChange={(e) => responder(9, e.target.value)}
								/>
							</Grid>
						</QuestionSlide>
					</SplideSlide>
				)} */}
				{step === 7 && (
					<SplideSlide>
						<QuestionSlide
							title={['Candidatura recebida com sucesso!', <br />]}
							subtitle={'Iremos avaliar sua candidatura e em breve entraremos em contato.'}
							classes={classes}
							step={7}
							onHandleAnswer={() => navigate('/')}
							questions={questions}
						></QuestionSlide>
					</SplideSlide>
				)}
			</Splide>
			{/* {step >= 0 && 7 > step ? (
				<Grid
					item
					container
					justifyContent={'center'}
					columnGap={1.5}
				>
					{questions.map((item, index) => {
						if (index !== 0 && index !== 7) {
							return (
								<Grid
									key={index}
									style={{ cursor: 'pointer', alignSelf: 'center' }}
									onClick={() => (index < step ? handleSteps(index) : null)}
								>
									<Typography
										style={{
											fontSize: index === step ? '3.5rem' : '2.5rem',
											color: index === step ? '#ffffff' : null,
										}}
									>
										&bull;
									</Typography>
								</Grid>
							);
						} else {
							return null;
						}
					})}
				</Grid>
			) : null} */}
		</Grid>
	);
};

export default function LegiaoHardworkResidencia() {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				<Grid
					container
					justifyContent={'space-between'}
					alignContent="center"
					className={`${matchesMobile ? classes.sectionMobile : classes.section}`}
					style={matchesMobile ? { marginTop: 80 } : matchesTablet ? { marginTop: 60 } : null}
					backgroundColor={'#09090A'}
				>
					<Grid
						item
						xs={12}
						md={6}
						container
						justifyContent={'flex-start'}
						className={`${classes.gridTextContainer} residencia`}
						gap={2}
						style={{ height: 'fit-content' }}
						paddingY={'5rem'}
					>
						<Typography
							className={`${classes.title}  Manrope`}
							color="secondary"
							fontSize={'2.75rem '}
						>
							LEGIÃO HARDWORK
						</Typography>
						<Divider
							variant="middle"
							className={classes.divider}
						/>
						{matchesMobile ? (
							<Typography
								align="left"
								className={`${classes.text} Manrope`}
								color={'#CCCCCC'}
								fontSize={'1.29rem'}
							>
								O Hardwork já tem seu primeiro programa de embaixadores!
								<br />
								<br />
								Na verdade, esse não é um simples programa de embaixadores...
								<br />
								O aluno de medicina merece mais; a medicina merece mais.
								<br />
								<br />
								A Legião Hardwork é um movimento de conexão intensa entre mestre e aprendiz. O mestre
								contribui profundamente com a tua formação; tu te conectas aos valores da Medicina
								Hardwork e auxilia na disseminação da nossa forma de ver a educação médica.
								<br />
								<br />
								Na Legião Hardwork tu não caminharás só pela medicina.
								<br />
								Nós iremos contigo.
								<br />
								<br />
								Selecionaremos alguns alunos de medicina de todo o Brasil para integrarem o primeiro
								grupo da Legião Hardwork.
								<br />
								A primeira etapa da seleção exige que tu preenchas o formulário ao lado.
								<br />
								<br />
								Em breve entraremos em contato com detalhes adicionais, como as próximas etapas e todos
								os benefícios de ser membro da Legião Hardwork.
								<br />
								<br />
								Estamos aqui para revolucionar.
								<br />
								<br />
								Abraço,
								<br />
								Fabrício Valois.
							</Typography>
						) : (
							<Typography
								align="left"
								className={`${classes.text} Manrope`}
								color={'#CCCCCC'}
								fontSize={'1.29rem'}
							>
								O Hardwork já tem seu primeiro programa de embaixadores!
								<br />
								<br />
								Na verdade, esse não é um simples programa de embaixadores...
								<br />
								O aluno de medicina merece mais; a medicina merece mais.
								<br />
								<br />
								A Legião Hardwork é um movimento de conexão intensa entre
								<br />
								mestre e aprendiz. O mestre contribui profundamente com a tua
								<br />
								formação; tu te conectas aos valores da Medicina Hardwork e
								<br />
								auxilia na disseminação da nossa forma de ver a educação médica.
								<br />
								<br />
								Na Legião Hardwork tu não caminharás só pela medicina.
								<br />
								Nós iremos contigo.
								<br />
								<br />
								Selecionaremos alguns alunos de medicina de todo o Brasil para
								<br />
								integrarem o primeiro grupo da Legião Hardwork.
								<br />
								A primeira etapa da seleção exige que tu preenchas o formulário ao
								<br />
								lado. Em breve entraremos em contato com detalhes adicionais,
								<br />
								como as próximas etapas e todos os benefícios de ser membro da
								<br />
								Legião Hardwork.
								<br />
								<br />
								Estamos aqui para revolucionar.
								<br />
								<br />
								Abraço,
								<br />
								Fabrício Valois.
							</Typography>
						)}
					</Grid>
					<Grid
						item
						xs={12}
						md={6}
						container
						justifyContent="center"
						className={classes.gridWithBackgroundImage}
						gap={matchesTablet ? 4 : 3}
					>
						<AssesmentCard classes={classes} />
					</Grid>
				</Grid>
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		margin: '1% auto 0',
	},
	sectionMobile: {
		padding: '2vh 5% 0',
	},
	title: {
		fontFamily: 'Manrope !important',
		fontWeight: 'bold !important',
		fontSize: '3.2rem !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
			marginBottom: '8px !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.45rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.18rem !important',
		},
	},
	subtitle: {
		fontFamily: 'Manrope !important',
		fontWeight: 'bold !important',
		fontSize: '1.25rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.95rem !important',
		},
	},
	text: {
		fontFamily: 'Manrope !important',
		lineHeight: '23.1px !important',
		fontWeight: '400 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	gridForm: {
		width: 567,
		flexDirection: 'column !important',
		alignItems: 'center !important',
		[theme.breakpoints.down('xl')]: {
			bottom: 20,
		},
		[theme.breakpoints.down(1300)]: {
			width: 467,
		},
		[theme.breakpoints.down('md')]: {
			position: 'unset',
			right: 'unset',
			top: 'unset',
			zIndex: 0,
		},
	},
	gridTextContainer: {
		[theme.breakpoints.down('lg')]: {
			paddingRight: '2em',
		},
		[theme.breakpoints.down('md')]: {
			paddingRight: 0,
		},
	},
	gridWithBackgroundImage: {
		paddingLeft: '2rem',
		[theme.breakpoints.down('lg')]: {
			padding: 'unset',
		},
		[theme.breakpoints.down('md')]: {
			backgroundImage: 'unset',
			marginTop: '2rem !important',
		},
	},
	logo: {
		marginBottom: '1rem',
		[theme.breakpoints.down('xl')]: {
			marginBottom: '2.5rem',
		},
		[theme.breakpoints.down('md')]: {
			marginBottom: '1rem',
		},
	},
	divider: {
		margin: 'unset !important',
		backgroundColor: '#707070',
		minHeight: 1,
		width: '100%',
	},
	paper: {
		height: '100%',
		padding: '1.5rem 0 0 !important',
		backgroundImage: `url(${require('../LandingPages/Assets/bg-live-residencia.png')})`,
		backgroundSize: 'cover',
		[theme.breakpoints.down('xl')]: {
			width: 585,
		},
		[theme.breakpoints.down('lg')]: {
			height: 'unset',
			width: 435,
			marginBottom: 0,
		},
		[theme.breakpoints.down('md')]: {
			width: 585,
		},
		[theme.breakpoints.down('sm')]: {
			height: 'unset',
			width: 'unset',
		},
	},
	gridTitle: {
		padding: '0 1rem',
		// width: '95% !important',
		borderRadius: 20,

		fontFamily: 'Manrope !important',
		height: 'fit-content',
		[theme.breakpoints.down('sm')]: {
			padding: '0px 15px',
		},
	},
	titleInformationalCardPrimary: {
		fontFamily: 'Manrope !important',
		marginTop: '2rem !important',
		fontSize: '2rem !important',
		fontWeight: '800 !important',
		margin: '8px 0 !important',
		lineHeight: 'unset !important',
		marginBottom: '1rem !important',
		textAlign: 'center !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '16px 0 !important',
			lineHeight: 'unset !important',
			fontSize: '1.25rem !important',
		},
	},
	titleInformationalCard: {
		marginTop: '1.5rem !important',
		fontFamily: 'Manrope !important',
		fontSize: '1.5rem !important',
		fontWeight: 'bold !important',
		margin: '8px 0 !important',
		lineHeight: 'unset !important',
		marginBottom: '1rem !important',
		textAlign: 'center !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '16px 0 !important',
			lineHeight: 'unset !important',
			fontSize: '1.25rem !important',
		},
	},
	subtitleInformationalCard: {
		textAlign: 'center !important',
		fontFamily: 'Manrope !important',
		fontSize: '1rem !important',
		fontWeight: '400 !important',
		margin: '8px 0 !important',
		lineHeight: 'unset !important',
		marginBottom: '1rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '16px 0 !important',
			lineHeight: 'unset !important',
			fontSize: '1.25rem !important',
		},
	},
	gridDescriptionCard: {
		backgroundColor: '#FFFFFF !important',
		padding: '1em 1.5em',
		borderRadius: 16,

		[theme.breakpoints.down('sm')]: {
			padding: '1em',
		},
	},
	paperDescription: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
		},
	},
	textDateStyle: {
		fontFamily: 'Manrope !important',
		borderRadius: 20,
		width: '240px !important',
		[theme.breakpoints.down('sm')]: {
			width: '200px !important',
		},
	},
	ctaTextCard: {
		fontFamily: 'Manrope !important',
		fontSize: '1.3rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
			fontWeight: '500 !important',
			margin: '5px 0 !important',
		},
	},
	form: {
		border: '1px solid #ffffff',
		backgroundColor: theme.palette.secondary.main,
		padding: '0.5rem !important',
		'& .MuiOutlinedInput-notchedOutline': {
			borderStyle: 'unset',
		},
	},
	startBtn: {
		fontFamily: 'Manrope !important',
		fontSize: '1rem !important',
		fontWeight: 'bold !important',
		padding: '0px !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
	},
	btn: {
		fontSize: '1.25rem !important',
		fontWeight: 'bold !important',
		paddingLeft: '1rem!important',
		paddingRight: '1rem!important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
	},
	slider: {
		height: '11px !important',
		padding: '0px 0 !important',
		'& .MuiSlider-track': {
			color: '#FD2C78 !important',
		},
		'& .MuiSlider-thumb': {
			color: '#ffffff',
			border: '1px solid #000000',
			width: '51px',
			borderRadius: '13px',
		},
		'& .MuiSlider-rail': {
			color: '#6A6A6A',
		},
	},
	splideMainContainer: {
		'& .splide': {
			height: '100%',
			marginTop: '1rem',
		},
	},
	radioBtnRoot: {
		color: '#ffffff !important',
		backgroundColor: '#FFFFFF1A',
		fontSize: '0.75rem !important',
		marginLeft: '1rem !important',
		overflowY: 'auto !important',
		maxHeight: '200px !important ',

		borderRadius: '8px',
		'& .MuiButtonBase-root-MuiRadio-root.Mui-checked': {
			color: '#ffffff',
			fontSize: '0.75rem !important',
		},
		'& .MuiTypography-root MuiTypography-body1 MuiFormControlLabel-label': {
			fontSize: '0.75rem !important',
		},
		'& .MuiTypography-root': {
			lineHeight: '13px !important',
		},
	},
	checkbox: {
		color: '#ffffff !important',
		// paddingTop: '0.25rem !important',

		alignSelf: 'start',
		'& .MuiFormControlLabel-root': {
			color: '#ffffff !important',
		},
	},
	gridFormScroll: {
		overflowY: 'auto',
		maxHeight: '240px',
		'&::-webkit-scrollbar-thumb': {
			background: '#ffffff',
		},
		'&::-webkit-scrollbar-track': {
			background: '#6D6D6D',
		},
	},
	spanStyle: {
		color: '#ffffff !important',
		fontSize: '0.9rem !important',
		lineHeight: '8px !important',
	},
	textSlider: {
		fontFamily: 'Manrope !important',
		fontSize: '1.5rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
	textAreaStyle: {
		color: '#ffffff',
		width: '100%',
		height: '3.5rem',
		marginTop: '10px',
		padding: '8px',
		border: '1px solid #FFFFFF26 ',
		backgroundColor: '#FFFFFF26 !important',
		borderRadius: ' 4px',
		fontFamily: 'Manrope',
		resize: 'vertical',
	},
}));
