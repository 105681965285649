import React, { useRef } from 'react';
import { Button, Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';

import ArrowForward from '../../../../../Assets/Svg/arrowForward.svg';
import ArrowBack from '../../../../../Assets/Svg/arrowBack.svg';
// import BgWave2 from '../../Assets/Img/bgWave2.png';
import { makeStyles } from '@mui/styles';
import LearningPathCard from './LearningPathCard';
import { v4 as uuidv4 } from 'uuid';
// import SimpleArrow from '../../Assets/Svg/simpleArrow.svg';

export default function LearningPathSectionCarrousel({ title, cardsInfo }) {
	const classes = useStyles();
	const theme = useTheme();
	const carousel = useRef(null);
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesDown738 = useMediaQuery(theme.breakpoints.down(738));

	const handleLeftClick = (e) => {
		e.preventDefault();

		carousel.current.scrollLeft -= carousel.current.offsetWidth;
	};

	const handleRightClick = (e) => {
		e.preventDefault();

		carousel.current.scrollLeft += 700;
	};

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
			style={{
				backgroundImage: `url(${require('../Assets/bg-gradient-price-card-trilha-r1-25.png')})`,
				backgroundSize: 'cover',
			}}
		>
			<Grid
				container
				alignContent="center"
				justifyContent="center"
				className={`${classes.backgroundWaves} ${classes.carouselSection}`}
			>
				<Container
					maxWidth={false}
					disableGutters={true}
				>
					<Container
						fixed
						maxWidth="xl"
						disableGutters={true}
					>
						<Grid
							item
							xs={12}
							container
							className={classes.gridTitle}
						>
							<Typography
								className={`${classes.subtitle1}`}
								fontFamily={'Manrope'}
								color="#FCFCFC"
							>
								{title}
							</Typography>
						</Grid>
					</Container>
					<Grid
						item
						className={classes.carouselGrid}
						xs={12}
					>
						<Button
							onClick={handleRightClick}
							className={`${classes.arrowBtn} ${classes.arrowBtnRight}`}
						>
							<img
								src={ArrowForward}
								alt=""
								className={classes.arrowIcon}
							/>
						</Button>
						{matchesDown738 ? (
							<Grid
								item
								container
								spacing={3}
								className={classes.gridCarouselContainer}
								style={matchesMobile ? { height: 609 } : null}
								ref={carousel}
							>
								{cardsInfo.map((item, index) => {
									return (
										<Grid
											item
											xs={12}
											md={4}
											key={index}
											className={cardsInfo.length - index === 1 ? classes.gridFeedbackCard : null}
										>
											<LearningPathCard
												key={index}
												{...item}
											/>
										</Grid>
									);
								})}
							</Grid>
						) : (
							<Grid
								item
								className={classes.gridLearningPathStatic}
							>
								<Button
									onClick={handleLeftClick}
									className={`${classes.arrowBtn} ${classes.leftArrowBtn}`}
								>
									<img
										src={ArrowBack}
										alt=""
										className={classes.arrowIcon}
									/>
								</Button>
								{/* <LearningPathCard
									key={uuidv4()}
									title={cardsInfo[0].title}
									description={cardsInfo[0].description}
									bgColor={cardsInfo[0].bgColor}
									titleColor={cardsInfo[0].titleColor}
									descriptionColor={cardsInfo[0].descriptionColor}
									url={cardsInfo[0].url}
								/> */}
							</Grid>
						)}
						{matchesDown738 ? null : (
							<Grid
								item
								container
								spacing={3}
								className={classes.gridCarouselContainer}
								ref={carousel}
							>
								{cardsInfo.map((item, index) => {
									return (
										<Grid
											item
											xs={12}
											key={index}
											className={
												window.location.pathname === '/trilha-r1'
													? null
													: cardsInfo.length - index === 1
													? classes.gridFeedbackCard
													: null
											}
											style={{
												maxWidth:
													window.location.pathname === '/trilha-r1' ? 'fit-content' : null,
											}}
										>
											<LearningPathCard
												key={index}
												{...item}
											/>
										</Grid>
									);
								})}
							</Grid>
						)}
					</Grid>
				</Container>
			</Grid>
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	backgroundWaves: {
		// backgroundImage: `url(${process.env.PUBLIC_URL + '/Assets/Img/bgWave2.png'})`,
		width: '100vw !important',
	},
	carouselSection: {
		padding: '2.5%',
		paddingLeft: '10%',
		[theme.breakpoints.down(1656)]: {
			paddingLeft: '8%',
		},
		[theme.breakpoints.down(1407)]: {
			paddingLeft: '6%',
		},
		[theme.breakpoints.down(1311)]: {
			paddingLeft: '2%',
		},
		[theme.breakpoints.down(1069)]: {
			paddingLeft: '6% !important',
		},
		[theme.breakpoints.down(933)]: {
			paddingLeft: '0% !important',
		},
		[theme.breakpoints.down(821)]: {
			paddingLeft: '10% !important',
		},
		[theme.breakpoints.down('sm')]: {
			height: 'unset !important',
			paddingLeft: 'unset !important',
			padding: '20px 2.5%',
		},
	},
	gridTitle: {
		padding: '0 0 2em 1em',
		[theme.breakpoints.down('sm')]: {
			margin: '16px 0px 16px 16px',
		},
	},
	subtitle1: {
		fontSize: '3rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('md')]: {
			lineHeight: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5rem !important',
			lineHeight: 'unset !important',
		},
	},
	carouselGrid: {
		display: 'flex',
		left: 64,
		[theme.breakpoints.down(1311)]: {
			left: 20,
		},
		[theme.breakpoints.down(933)]: {
			left: 60,
		},
		[theme.breakpoints.down(821)]: {
			left: 10,
		},
	},
	arrowBtn: {
		position: 'absolute !important',
		zIndex: 1000,
		borderRadius: '50% !important',
		width: 80,
		height: 80,
		backgroundColor: '#2D2D2D !important',
		opacity: 0.75,
		'&:hover': { opacity: 1, transition: '0.2s' },
		[theme.breakpoints.down('sm')]: {
			display: 'none !important',
		},
	},
	arrowIcon: {
		width: '47%',
	},
	gridCarouselContainer: {
		display: 'flex !important',
		overflowX: 'scroll',
		scrollBehavior: 'smooth',
		flexWrap: 'nowrap !important',
		minWidth: '100vw',
		// left: '22%',
		position: 'absolute',
		// [theme.breakpoints.down(1751)]: {
		// 	left: '24%',
		// },
		// [theme.breakpoints.down(1601)]: {
		// 	left: '27%',
		// },
		// [theme.breakpoints.down('xl')]: {
		// 	left: '29%',
		// },
		// [theme.breakpoints.down(1409)]: {
		// 	left: '34%',
		// },
		// [theme.breakpoints.down('lg')]: {
		// 	left: '36%',
		// },
		// [theme.breakpoints.down(1075)]: {
		// 	left: '41%',
		// },
		// [theme.breakpoints.down(1005)]: {
		// 	left: '44%',
		// },
		// [theme.breakpoints.down(929)]: {
		// 	left: '47%',
		// },
		// [theme.breakpoints.down(821)]: {
		// 	left: '52%',
		// },
		// [theme.breakpoints.down(797)]: {
		// 	left: '50%',
		// },
		[theme.breakpoints.down(738)]: {
			position: 'relative',
			left: '20px !important',
		},
		[theme.breakpoints.down('sm')]: {
			height: 400,
			overflowX: 'auto',
			overflowY: 'hidden',
			paddingLeft: '7%',
			left: 'unset !important',
		},
	},
	gridFeedbackCard: {
		paddingRight: 1150,
		[theme.breakpoints.down('lg')]: {
			paddingRight: 850,
		},
		[theme.breakpoints.down('sm')]: {
			paddingRight: 20,
		},
	},
	gridLearningPathStatic: {
		position: 'relative',
		height: 821,
		zIndex: 2,
		[theme.breakpoints.down('xl')]: {
			left: 20,
			height: 675,
		},
		[theme.breakpoints.down(1311)]: {
			left: 40,
		},
		[theme.breakpoints.down(1075)]: {
			left: 20,
		},
		[theme.breakpoints.down(802)]: {
			left: 0,
		},
		[theme.breakpoints.down(772)]: {
			left: '-10px',
		},
	},
	leftArrowBtn: {
		left: -90,
		top: 150,
		[theme.breakpoints.down(1311)]: {
			left: -62,
		},
	},
	arrowBtnRight: {
		right: 80,
		top: 150,
		[theme.breakpoints.down('xl')]: {
			right: 60,
		},
		[theme.breakpoints.down(1311)]: {
			right: 40,
		},
		[theme.breakpoints.down('lg')]: {
			right: 0,
		},
	},
}));
