import React from 'react';
import { Divider, Grid, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Api from '../../Services/Api';
import DefaultButton from '../Buttons/DefaultButton';
import { useState } from 'react';

import R3CMCardBackground from '../../Assets/Extensivo/R3CM/cardBackground.png';
import EXTR120232024CardBackground from '../../Assets/Extensivo/R1/2023-24/cardBackground.png';
import CountDownTimer from '../../Pages/LandingPages/Sale/LDL/components/CountDownTimer';
import IntlTelInput from 'react-intl-tel-input';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { trackPromise } from 'react-promise-tracker';
import Swal from 'sweetalert2';
import { width } from '@mui/system';

// import R3CGCardBackground from '../../Assets/Extensivo/R3CG/cardBackground.png';

export default function PriceCardTrilhaR3({
	idTurma,
	logo,
	iconBtn,
	idomedStudents,
	bgImageUrl,
	color,
	discount,
	setPromotionalDate,
}) {
	const classes = useStyles(idTurma);
	const theme = useTheme();
	const [turma, setTurma] = useState([]);
	const [lotValueLimit, setLotValueLimit] = useState();
	const [lot, setLot] = useState();
	const currentDate = new Date();
	const [endDate, setEndDate] = useState();
	const [activePrice, setActivePrice] = useState();
	const [waitListVisibility, setWaitListVisibility] = useState('hidden');
	const [preInscricao, setPreInscricao] = useState({
		agrupamento: `Trilha R3 - Clínica Médica 2025`,
		nome: '',
		email: '',
		telefone: '',
		utm_source: '',
		utm_medium: '',
		utm_campaign: '',
	});

	const matchesDown361 = useMediaQuery(theme.breakpoints.down(361));
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	const handleOpenWaitList = () => {
		setWaitListVisibility('unset');
	};

	const handleCloseWaitList = () => {
		setWaitListVisibility('hidden');
	};

	const handlePhone = (valid, phoneNumber) => {
		if (valid) {
			setPreInscricao({
				...preInscricao,
				telefone: phoneNumber,
			});
		} else if (preInscricao.telefone.length) {
			setPreInscricao({
				...preInscricao,
				telefone: '',
			});
		}
	};

	let sendPreInscricao = function () {
		if (!preInscricao.nome) {
			alert('Ops... Por favor informe seu nome');
			return;
		}
		if (!preInscricao.email || !preInscricao.email.includes('@') || !preInscricao.email.includes('.')) {
			alert('Ops... Por favor preencha seu email');
			return;
		}
		if (!preInscricao.telefone || preInscricao.telefone.length < 11) {
			alert('Ops... Por favor preencha seu telefone');
			return;
		}

		trackPromise(
			Api.post('PreInscricao', preInscricao).then((res) => {
				if (res) {
					Swal.fire({
						title: 'Massa! ',
						text: 'Sua inscrição na lista de espera está confirmada! Avisaremos assim que a nova turma estiver liberada.',
						icon: 'success',
						confirmButtonText: 'Fechar',
						allowOutsideClick: false,
						allowEscapeKey: false,
					}).then((result) => {
						if (result.isConfirmed) {
							window.location.reload();
						}
					});
				}
			})
		);
	};

	useEffect(() => {
		Api.get(`turmasabertas/${idTurma}`).then((res) => {
			setActivePrice(res[0]?.lotes[0]?.valor);

			if (res) setTurma(res[0]);

			const activeLot = res[0]?.lotes?.find((lot, index) => {
				return lot?.id === res[0]?.id_lote_vigente;
			});
			if (activeLot) {
				const activeLotIndex = res[0]?.lotes?.indexOf(activeLot);
				const currentEndDateFormatted = new Date(activeLot?.data_limite);
				if (activeLotIndex + 1 !== res[0]?.lotes?.length) {
					setEndDate(currentEndDateFormatted);
				}
			}
			if (setPromotionalDate) setPromotionalDate(activeLot);
			setLot(activeLot);
			setLotValueLimit(new Date(activeLot?.data_limite).getTime());
		});
	}, [idTurma]);

	return (
		<Grid
			container
			alignContent={matchesTablet ? 'space-around' : 'center'}
			justifyContent="space-between"
			className={classes.extensivoR1Card}
			style={
				color
					? {
							border: window.location.pathname === '/trilha-r1-2024' ? null : '2px solid black',
							// padding: matchesTablet ? '1rem 0' : 0,
							height: discount ? 'unset' : null,
					  }
					: null
			}
		>
			<Grid
				item
				xs={12}
				md={7}
				container
				direction={window.location.pathname === '/trilha-r3-clinica-medica' ? 'column' : 'row'}
				justifyContent="center"
				alignItems="center"
				className={classes.gridImg}
				// FIX this
				style={
					bgImageUrl
						? {
								backgroundImage: `url(${bgImageUrl})`,
								backgroundSize: 'cover',
								borderRadius: 15,
						  }
						: {
								backgroundImage: `url(${
									idTurma === '1174' || idTurma === '1157' || idTurma === '1187' || idTurma === '1203'
										? EXTR120232024CardBackground
										: idTurma === '1175'
										? R3CMCardBackground
										: null
								})`,
						  }
				}
			>
				<img
					src={logo}
					alt=""
					className={classes.extensivoLogoImg}
				/>
				{window.location.pathname === '/trilha-r3-clinica-medica' && (
					<>
						<Divider
							variant="middle"
							className={classes.divider}
							style={{ width: matchesMobile ? '100%' : '85%' }}
						/>
						<Grid
							item
							style={{
								backgroundColor: '#5829B2',
								borderRadius: 4,
								width: 'fit-content',
								padding: '0.5rem 1rem',
								marginTop: '0.75rem',
							}}
						>
							<Typography
								color="secondary"
								className="poppins"
								style={matchesMobile ? { fontSize: '0.7rem' } : { alignSelf: 'center', fontSize: 14 }}
							>
								20% ex-aluno extensivos e 10% ex-aluno sprint
							</Typography>
						</Grid>
					</>
				)}
			</Grid>
			<Grid
				item
				xs={12}
				md={5}
				container
				justifyContent="center"
				alignContent="center"
				className={color ? classes.gridPriceInfoBlack : classes.gridPriceInfo}
				style={
					turma?.encerrada
						? { padding: '1em 0' }
						: discount
						? { padding: matchesTablet ? 0 : '1.5rem 0' }
						: { paddingTop: endDate !== undefined ? null : '2rem' }
				}
				gap={window.location.pathname === '/sprint-final-revalida' ? 1 : 0}
			>
				<Grid
					item
					container
					justifyContent={matchesTablet ? 'center' : 'flex-end'}
					alignContent="center"
					rowGap={1}
				>
					{endDate >= currentDate && (
						<Grid
							item
							container
							justifyContent={'space-between'}
							wrap={'nowrap'}
							columnGap={2}
							style={{
								backgroundColor: '#ffffff',
								padding: matchesMobile ? '0.5rem 1rem 0.25rem' : '0.5rem 0.75rem 0',
								borderRadius: '0 0 8px 8px',
								marginRight: matchesTablet ? 'unset' : '1rem',
								width: 'fit-content',
							}}
						>
							<Typography
								color={'primary'}
								className="poppins"
								style={{
									fontSize: matchesMobile ? '0.7rem' : '0.8rem',
									alignSelf: matchesMobile ? 'center' : null,
								}}
							>
								<strong>Promoção</strong>
								<br />
								se encerra em
							</Typography>

							<CountDownTimer
								targetDate={endDate}
								textColor={'primary'}
							/>
						</Grid>
					)}
					<Grid
						item
						xs={12}
						container
						direction={'column'}
						justifyContent={turma?.encerrada ? 'center' : 'flex-start'}
						className={classes.priceContainerInfo}
						style={
							matchesMobile
								? { width: turma?.encerrada ? null : 'unset' }
								: turma?.encerrada
								? { width: '70%', margin: '2rem 0' }
								: {
										width: window.location.pathname === '/trilha-r1-2024' ? '100%' : '70%',
								  }
						}
					>
						{turma?.encerrada ? (
							<>
								<Typography
									color={color ? 'secondary' : 'primary'}
									align="left"
									className={`${classes.subscriptionInactiveText} roboto-condensed`}
								>
									EM BREVE
								</Typography>
								<DefaultButton
									variant="outlined"
									color="secondary"
									// className={`${classes.btn} btn`}
									style={{ backgroundColor: '#00AB55', width: '300px' }}
									onClick={() => handleOpenWaitList()}
								>
									{matchesMobile ? null : (
										<img
											src={iconBtn}
											alt=""
										/>
									)}
									Avise-me
								</DefaultButton>
							</>
						) : (
							<>
								{turma?.valor !== lot?.valor && (
									<Typography
										color={color ? 'primary' : 'secondary'}
										align="left"
										className={`${classes.priceTextStrikeThrough} poppins`}
										style={{ marginBottom: '10px', marginTop: '10px' }}
									>
										De: R${' '}
										{turma?.valor?.toLocaleString('pt-BR', {
											minimumFractionDigits: 2,
											maximumFractionDigits: 2,
										})}
									</Typography>
								)}
								{turma?.valor !== lot?.valor && (
									<Typography
										color={color ? 'primary' : 'secondary'}
										align="left"
										className={`${classes.priceTextWhite} poppins`}
										style={{ marginBottom: '10px', marginTop: '0px' }}
									>
										Por: R${' '}
										{activePrice.toLocaleString('pt-BR', {
											minimumFractionDigits: 2,
											maximumFractionDigits: 2,
										})}
									</Typography>
								)}
								<Typography className={`${classes.priceTextWhite} roboto-condensed`}>
									Até{' '}
									{turma?.parcelas && turma?.parcelas.length > 0
										? turma?.parcelas[turma?.parcelas.length - 1]?.qtd
										: ''}
									x de{<br />}
									<strong className={`${classes.priceTextWhiteUnder} roboto-condensed`}>
										R${' '}
										{turma?.parcelas && turma?.parcelas.length > 0
											? turma?.parcelas[turma?.parcelas.length - 1]?.valor?.toLocaleString(
													'pt-BR',
													{
														minimumFractionDigits: 2,
														maximumFractionDigits: 2,
													}
											  )
											: ''}
									</strong>
								</Typography>
								{/* <Typography
									className={`${classes.priceTextGrey} poppins`}
									style={{ paddingTop: '10px' }}
								>
									Ou à vista por: R${' '}
									{lot
										? lot?.valor?.toLocaleString('pt-BR', {
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
										  })
										: turma?.valor?.toLocaleString('pt-BR', {
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
										  })}
								</Typography> */}
							</>
						)}
					</Grid>
					{window.location.pathname !== '/trilha-r3-clinica-medica' && (
						<>
							<Grid
								item
								xs={12}
								className={classes.gridDivider}
							>
								<Divider
									variant="middle"
									className={classes.divider}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								className={classes.gridDivider}
								style={{ marginBottom: '0.5rem' }}
							>
								<Typography className={`${classes.price24x} poppins`}>
									Ou até{' '}
									{turma?.parcelas && turma?.parcelas.length > 0 ? turma?.parcelas[11]?.qtd : ''}x de
									R${' '}
									{turma?.parcelas && turma?.parcelas?.length > 0
										? turma?.parcelas[11]?.valor?.toLocaleString('pt-BR', {
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
										  })
										: ''}{' '}
									sem juros
								</Typography>
							</Grid>
						</>
					)}
					{turma?.encerrada ? null : (
						<>
							<Grid
								item
								container
								alignContent={'center'}
								justifyContent={'center'}
								style={matchesTablet ? null : { height: '4rem' }}
							>
								<DefaultButton
									variant="outlined"
									color="secondary"
									marginTop={5}
									className={`${classes.btn} btn button-info-card`}
									component={Link}
									to={`//hardworkmedicina.com.br/inscricao/${idTurma}`}
								>
									{matchesMobile ? null : (
										<img
											src={iconBtn}
											alt=""
											className={classes.iconBnt}
										/>
									)}
									&nbsp; Inscreva-se
								</DefaultButton>
							</Grid>
						</>
					)}
					<Grid
						item
						container
						justifyContent={'center'}
						className={classes.containerWaitList}
						rowGap={1}
						style={{ visibility: waitListVisibility }}
					>
						<Grid
							item
							container
							justifyContent="space-between"
						>
							<Typography
								color="primary"
								className={`${classes.textWaitList} poppins`}
							>
								Inscrever-se na Lista de Espera
							</Typography>
							<CancelRoundedIcon
								onClick={() => handleCloseWaitList()}
								className={classes.cancelIcon}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							md={6}
							container
							justifyContent={matchesTablet ? 'center' : 'flex-start'}
						>
							<TextField
								fullWidth
								type="string"
								name="nome"
								placeholder="Seu nome completo"
								required={true}
								InputProps={{
									className: `${classes.form} ${classes.formHeight}`,
								}}
								InputLabelProps={{
									shrink: true,
								}}
								style={matchesTablet ? { width: '100%' } : { width: '97%' }}
								onChange={(e) =>
									setPreInscricao({
										...preInscricao,
										nome: e.target.value,
									})
								}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							md={6}
							container
							justifyContent={matchesTablet ? 'center' : 'flex-end'}
							alignContent={'center'}
						>
							<IntlTelInput // Componente Input de telefones internacionais
								name="telefone"
								type="telefone"
								preferredCountries={['BR', 'PY', 'AR', 'CL', 'BO', 'UY', 'CU', 'PT']}
								style={{ width: '100%' }}
								inputClassName={`${classes.phoneNumberForm} ${classes.form} ${classes.formHeight}`}
								nationalMode={true}
								onPhoneNumberChange={(valid, value, selectedCountryData, fullNumber) =>
									handlePhone(valid, fullNumber)
								}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							container
							justifyContent={matchesMobile ? 'center' : 'flex-start'}
						>
							<TextField
								fullWidth
								type="email"
								name="email"
								placeholder="Seu melhor e-mail"
								required={true}
								InputProps={{
									className: `${classes.form} ${classes.formHeight}`,
								}}
								InputLabelProps={{
									shrink: true,
								}}
								style={{ width: '100%' }}
								onChange={(e) =>
									setPreInscricao({
										...preInscricao,
										email: e.target.value,
									})
								}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							md={10}
							lg={8}
							container
							className="residencia"
							style={{ height: 50, padding: '4px 0px' }}
						>
							<DefaultButton
								// type="submit"
								variant="outlined"
								color="secondary"
								className={`${classes.btnWaitList} btn`}
								onClick={() => sendPreInscricao()}
							>
								<img
									src={require('../../Assets/Arena/icon-waitList.svg').default}
									alt=""
									style={{ width: 20 }}
								/>{' '}
								&nbsp; Confirmar inscrição
							</DefaultButton>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	originalPriceText: {
		fontSize: '1.5rem !important',
		color: '#A6A6A6 !important',
		textDecoration: 'line-through',
	},
	extensivoR1Card: {
		borderRadius: 13,
		// border: '2px solid black !important',
		[theme.breakpoints.down('md')]: {
			height: 'unset',
		},
		[theme.breakpoints.down('sm')]: {
			// width: '90% !important',
		},
	},
	gridImg: {
		height: '100%',
		borderBottomLeftRadius: 15,
		paddingTop: '1rem !important',
		[theme.breakpoints.down('md')]: {
			height: 'unset',
		},
		[theme.breakpoints.down('sm')]: {
			padding: 25,
			borderRadius: 25,
		},
	},
	extensivoLogoImg: {
		width: window.location.pathname === '/trilha-r3-clinica-medica' ? 'unset' : '80%',
		maxHeight: '65%',
		[theme.breakpoints.down('md')]: {
			padding: '0px',
			maxHeight: 'unset',
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			padding: '0px 10px',
		},
	},
	gridPriceInfo: {
		backgroundColor: '#ffffff !important',
		borderRadius: 15,
		[theme.breakpoints.down('sm')]: {
			padding: '8px 0 ',
		},
	},
	gridPriceInfoBlack: {
		borderBottomRightRadius: 15,
		borderTopRightRadius: 15,
		[theme.breakpoints.down('sm')]: {
			padding: '0 0 8px',
		},
	},
	priceTextStrikeThrough: {
		fontSize: '1.5rem !important',
		color: '#A6A6A6 !important',
		lineHeight: 'unset !important',
		textDecoration: 'line-through',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
	},
	priceTextWhite: {
		color: 'white !important',
		fontSize: '2rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '2rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down(376)]: {
			fontSize: '1.5rem !important',
		},
	},
	priceTextWhiteUnder: {
		color: 'white !important',
		fontSize: '4.25rem !important',
		fontWeight: 'bold !important',
		lineHeight: '0.9 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '3rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '3.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.5rem !important',
		},
		[theme.breakpoints.down(376)]: {
			fontSize: '2.5rem !important',
		},
	},
	priceTextGrey: {
		fontSize: '1.4rem !important',
		color: 'grey !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.25rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.25rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down(376)]: {
			fontSize: '1rem !important',
		},
	},
	iconBnt: {
		width: '7%',
	},
	btn: {
		width: '75%',
		height: '65%',
		marginBottom: '1rem !important',
		fontSize: '1.35rem !important',
		minHeight: 'unset !important',
		fontWeight: 'bold !important',
		borderColor: 'transparent',
		backgroundColor: '#00AB55 !important',
		[theme.breakpoints.up('lg')]: {
			'&:hover': {
				minWidth: '72% !important',
				fontSize: '1.5rem !important',
				backgroundColor: '#00543b !important',
				transition: '0.1s',
			},
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.2rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
			width: '90%',
		},
	},
	containerWaitList: {
		width: '100% !important',
		backgroundColor: '#d6cece',
		zIndex: 1,
		borderRadius: 15,
		padding: '0.5rem 1.25rem',
		boxShadow: '0px 0px 30px 0px #000000',
		border: '2px solid #000000',
		position: 'absolute',
		bottom: 0,
		[theme.breakpoints.down('sm')]: {
			padding: '1.5rem 1.25rem',
		},
	},
	textWaitList: {
		fontSize: '1.3rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.95rem !important',
		},
	},
	form: {
		borderRadius: '8px !important',
		backgroundColor: '#E1E1E1 !important',
		margin: '5px 0 !important',
	},
	formHeight: {
		maxHeight: '3em',
	},
	phoneNumberForm: {
		height: '3em',
		borderRadius: 8,
		width: '100%',
		border: 'unset',
	},
	cancelIcon: {
		color: '#000000',
		right: '-15px',
		top: '-5px',
		'&:hover': {
			cursor: 'pointer',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'absolute',
			top: '-20px',
			right: '-18px',
		},
	},
	btnWaitList: {
		fontSize: '1rem !important',
		minHeight: 'unset !important',
		fontWeight: 'bold !important',
		backgroundColor: '#00AB55 !important',
		border: '1px solid #707070 !important',
		[theme.breakpoints.up('lg')]: {
			'&:hover': {
				minWidth: '72% !important',
				fontSize: '1.025rem !important',
				backgroundColor: '#00543b !important',
				transition: '0.1s',
			},
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
		},
	},
	discount: {
		fontSize: '1.1rem',
		color: 'red',
		alignSelf: 'center',
		marginTop: '0.5em',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
			margin: '0.5em 0',
		},
	},
	disclaimerText: {
		fontSize: '1rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	subscriptionInactiveText: {
		fontSize: '3.25rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5rem !important',
			margin: '1rem !important',
		},
	},
	soonStripeImg: {
		position: 'absolute',
		top: -18,
		right: -90,
		[theme.breakpoints.down('xl')]: {
			right: -77,
		},
	},
	divider: {
		margin: '0.5rem auto !important',
		backgroundColor: '#6D6D6D',
		minHeight: 1,
	},
	priceContainerInfo: {
		paddingLeft: '2rem',
	},
	gridDivider: {
		paddingRight: '2rem',
		paddingLeft: '2rem',
	},
	price24x: {
		fontSize: '1rem !important',
		color: 'grey !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
		},
	},
}));
