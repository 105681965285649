import React, { useState, useEffect } from 'react';
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
// import { Link } from 'react-router-dom';
import Api from '../Services/Api';
import { Splide, SplideSlide } from '@splidejs/react-splide';

// COMPONENTS
import InfoCard from '../Components/Cards/InfoCard';
import Footer from '../Components/Footer';
import { Header } from '../Components/Header';
import { Helmet } from 'react-helmet';

export default function HomePage() {
	const classes = useStyles();
	const theme = useTheme();
	const [banners, setBanners] = useState([]);

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	useEffect(() => {
		Api.get(`banners/home`).then((res) => {
			setBanners(res);
		});
	}, []);

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			<Helmet>
				<title>Hardwork Medicina | Pioneiro no Estudo por Questões e Preparação para Residência</title>
				<meta
					name="description"
					content="O Hardwork é líder no estudo por questões e revisões personalizadas para
provas de residência médica e Revalida INEP, oferecendo a melhor preparação
para aprovação."
				/>
			</Helmet>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				{/* <Grid>
					{banner.map((item) => (
						<Grid
							item
							container
							justifyContent={'center'}
						>
							<a
								href={item.link_url}
								rel="noreferrer"
							>
								<img
									src={matchesMobile ? item.imagem_p : item.imagem_g}
									alt="banner"
									style={
										matchesMobile
											? { marginTop: '4.8rem', border: 'solid 1px #707070', borderRadius: '8px' }
											: { marginTop: '1.5rem', border: 'solid 1px #707070', borderRadius: '8px' }
									}
								/>
							</a>
						</Grid>
					))}
				</Grid> */}
				{banners.length > 0 && (
					<Splide
						className={classes.splideMainContainer}
						options={{ arrows: false, autoplay: true, type: 'loop', speed: 50 }}
					>
						{banners.map((item, index) => {
							return (
								<SplideSlide key={index}>
									<Grid
										item
										container
										justifyContent={'space-around'}
									>
										<Grid
											item
											key={item.id}
											className={classes.gridImage}
										>
											<a
												href={item.link_url}
												rel="noreferrer"
											>
												<img
													src={matchesMobile ? item.imagem_p : item.imagem_g}
													alt=""
													className={classes.image}
													style={
														matchesMobile
															? {
																	marginTop: '4.8rem',
																	border: 'solid 1px #707070',
																	borderRadius: '8px',
															  }
															: {
																	marginTop: '1.5rem',
																	border: 'solid 1px #707070',
																	borderRadius: '8px',
															  }
													}
												/>
											</a>
										</Grid>
									</Grid>
								</SplideSlide>
							);
						})}
					</Splide>
				)}

				<Grid
					container
					alignContent={matchesTablet ? 'space-around' : 'center'}
					justifyContent="center"
					className={`${matchesMobile ? classes.sectionMobile : classes.section} main-section`}
				>
					<Grid
						item
						xs={12}
						md={8}
						container
						justifyContent="center"
						alignContent="center"
						className={`${classes.containerInfoCards} container-info-cards`}
					>
						<Grid container>
							<Grid
								item
								xs={12}
								container
								justifyContent="center"
								alignContent="center"
								className={`${
									matchesMobile || matchesTablet
										? classes.displayNone
										: classes.containerMotivationalAndImage
								} containerMotivationalAndImage`}
							>
								<img
									src={process.env.PUBLIC_URL + '/Assets/fabricioFabioImage.svg'}
									alt="Fabrício Valois e Fábio Colagrossi"
									className={`${classes.fabricioFabioImage} fabricio-fabio-img`}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								md={6}
								container
								justifyContent="center"
								alignContent="center"
								className={`${
									matchesMobile || matchesTablet
										? classes.marginGridInfoCard
										: classes.gridContainerInfoCardLeft
								} grid-home-infocards`}
							>
								<img
									src={process.env.PUBLIC_URL + '/Assets/fabricioMotivationalMobile2.png'}
									alt="Fabrício Valois"
									className={matchesMobile || matchesTablet ? classes.imgMobile : classes.displayNone}
								/>
								<Grid
									item
									xs={12}
									container
									// justifyContent="center"
									alignContent="center"
									className={`${classes.card} home-card`}
								>
									<InfoCard type="residencia" />
								</Grid>
							</Grid>
							<Grid
								item
								xs={12}
								md={6}
								container
								justifyContent="center"
								alignContent="center"
								className={`${
									matchesMobile || matchesTablet
										? classes.marginGridInfoCard
										: classes.gridContainerInfoCardRight
								} grid-home-infocards`}
							>
								{/* <img
									src={FabioMotivationalMobile}
									alt="Fábio Colagrossi"
									className={matchesMobile || matchesTablet ? classes.imgMobile : classes.displayNone}
								/> */}
								<Grid
									item
									xs={12}
									container
									justifyContent={matchesMobile || matchesTablet ? 'center' : 'flex-start'}
									alignContent="center"
									className={`${classes.card2} home-card`}
								>
									<InfoCard type="revalida" />
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						md={4}
						container
						justifyContent="center"
						alignContent="center"
						style={matchesTablet ? { margin: '10% 0' } : null}
					>
						<Grid
							item
							xs={11}
							md={12}
							container
						>
							<Grid
								item
								xs={12}
								container
								alignContent="center"
							>
								<Grid
									item
									xs={12}
									container
									alignContent="center"
								>
									<Typography
										align="left"
										variant="h1"
										className={`${classes.title} residencia gradient-text title-home`}
									>
										{/* Reconstruindo
										<br />
										a educação
										<br />
										médica */}
										Revolucionamos a
										<br />
										preparação para
										<br />
										as provas!
									</Typography>
								</Grid>
								<Grid
									item
									xs={11}
									md={12}
									className={`${classes.gridTextH1} home-page-subtitle`}
								>
									{matchesMobile ? (
										<Typography
											align="left"
											color="#DCDCDC"
											className={`${classes.greyText} home-page-text-content`}
										>
											Estamos sempre à frente!
											<br />
											Sempre a melhor
											<br />
											metodologia!
											<br />
											O Hardwork é pioneiro
											<br />
											no Estudo por questões
											<br />
											e nas revisões focadas
											<br />
											em cada um das provas!
										</Typography>
									) : (
										<Typography
											align="left"
											color="#DCDCDC"
											className={`${classes.greyText} home-page-text-content`}
										>
											Estamos sempre à frente!
											<br />
											Sempre a melhor
											<br />
											metodologia!
											<br />
											O Hardwork é pioneiro no
											<br />
											Estudo por questões e nas
											<br />
											revisões focadas em
											<br />
											cada uma das provas!
										</Typography>
									)}
								</Grid>
							</Grid>
							<Grid
								item
								xs={3}
								className={classes.gridFabricioImg}
							>
								<img
									src={process.env.PUBLIC_URL + '/Assets/fabricio.png'}
									alt="Fabricio Valois"
									className={`${classes.img} fabricio-img`}
								/>
							</Grid>
						</Grid>
						{/* <Grid
							item
							xs={11}
							className={`${classes.gridItemDefaultBtn} grid-default-btn`}
						>
							<DefaultButton
								variant="outlined"
								color="secondary"
								className={`${classes.btn} btn-secondary poppins`}
								// component={Link}
								// to="/sobre-nos"
								// disabled
								style={{ cursor: 'auto' }}
							>
								Conheça o Hardwork
							</DefaultButton>
						</Grid> */}
					</Grid>
				</Grid>
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		marginRight: 'auto',
		marginLeft: 'auto',
		minHeight: 700,
		[theme.breakpoints.down('xl')]: {
			minHeight: 590,
		},
		[theme.breakpoints.down('lg')]: {
			minHeight: 430,
		},
		[theme.breakpoints.down('md')]: {
			minHeight: 700,
		},
	},
	containerInfoCards: {
		height: '100%',
		[theme.breakpoints.down('xl')]: {
			top: -18,
		},
		[theme.breakpoints.down('lg')]: {
			top: -20,
		},
	},
	card: {
		[theme.breakpoints.down('md')]: {
			marginTop: '48% !important',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'relative',
			bottom: '1em',
		},
	},
	card2: {
		[theme.breakpoints.down('md')]: {
			marginTop: '-40px !important',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'relative',
			bottom: '1em',
		},
	},
	title: {
		fontWeight: 'bold !important',
		fontSize: '3.5rem !important',
		lineHeight: '1 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.75rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '3.2rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.375rem !important',
		},
	},
	greyText: {
		fontSize: '1.315rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.95rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '.875rem !important',
			lineHeight: '1.2 !important',
		},
	},
	img: {
		position: 'absolute',
		top: '1em',
		right: '-25%',
		maxWidth: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			width: '150px !important',
			top: '2em !important',
		},
		[theme.breakpoints.down('md')]: {
			top: '0 !important',
			width: '215px !important',
			right: '8% !important',
		},
		[theme.breakpoints.down('sm')]: {
			width: '145px !important',
			top: '0em !important',
			right: '-6px !important',
		},
	},
	btn: {
		fontSize: '1.15rem !important',
		minHeight: 'unset !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
	// gridItemDefaultBtn: {
	// 	marginTop: '1.6rem !important',
	// 	[theme.breakpoints.down('xl')]: {
	// 		marginTop: '1.35rem !important',
	// 	},
	// 	[theme.breakpoints.down('lg')]: {
	// 		marginTop: '0 !important',
	// 	},
	// 	[theme.breakpoints.down('md')]: {
	// 		marginTop: '1.85rem !important',
	// 	},
	// 	[theme.breakpoints.down('sm')]: {
	// 		marginTop: '20px !important',
	// 	},
	// },
	containerMotivationalAndImage: {
		height: '100%',
		[theme.breakpoints.down(1500)]: {
			height: 'unset',
		},
	},
	displayNone: {
		display: 'none !important',
	},
	gridContainerInfoCardLeft: {
		position: 'absolute',
		left: 0,
		bottom: '-40%',
		top: 162,
		[theme.breakpoints.down('lg')]: {
			bottom: '-40% !important',
			top: 'unset',
		},
	},
	gridContainerInfoCardRight: {
		position: 'absolute',
		right: 0,
		bottom: '-40%',
		top: 162,
		[theme.breakpoints.down('lg')]: {
			bottom: '-39% !important',
			top: 'unset',
		},
	},
	sectionMobile: {
		padding: '2vh 3%',
		marginTop: 30,
	},
	imgMobile: {
		width: '80%',
		position: 'absolute',
	},
	fabricioFabioImage: {
		width: '80% !important',
		position: 'relative',
		top: '-10%',
		maxWidth: 'unset',
		left: '-5%',
	},
	gridTextH1: {
		margin: '0.5em 0 !important',
		[theme.breakpoints.down('sm')]: {
			marginTop: '10px !important',
		},
	},
	marginGridInfoCard: {
		margin: '25px 0 !important',
	},
	gridFabricioImg: {
		position: 'absolute',
		right: '-5em',
		top: '2em',
		[theme.breakpoints.down('xl')]: {
			right: '-3em',
			top: '0',
		},
		[theme.breakpoints.down('md')]: {
			right: '0',
		},
		[theme.breakpoints.down('sm')]: {
			right: '-1em',
			top: '3em',
		},
	},
}));
