import { Alert, useMediaQuery, useTheme } from '@mui/material';
import { Header } from '../../Components/Header';
import Footer from '../../Components/Footer';
import FirstSection from '../../Components/Legion/FirstSection';
import Form from '../../Components/Legion/Form';
import { useEffect, useRef, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import Api from '../../Services/Api';

export default function Legiaohardwork() {
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesUpXl = useMediaQuery(theme.breakpoints.up('xl'));

	const [isSearching, setIsSearching] = useState(false);
	const [step, setStep] = useState(0);
	const [canPass, setCanPass] = useState(false);

    const refForm = useRef(null);

	const [formData, setFormData] = useState({
		id_pesquisa: 1002,
		cod_respondente: '',
		nome: '',
		email: '',
		telefone: '',
		respostas: ['', '', '', '', '', ''],
		complementos: ['', '', '', '', '', ''],
	});

	const FormInfos = [
		{
			titulo: 'Entre para a legião!',
			codigo: 'P0',
			infos: [
				{
					type: 'text',
					placeholder: 'Seu nome completo',
					onChange: (e) => setFormData({ ...formData, nome: e.target.value }),
					value: formData.nome,
				},
				{
					type: 'email',
					placeholder: 'Seu melhor e-mail',
					onChange: (e) => setFormData({ ...formData, email: e.target.value }),
					value: formData.email,
				},
				{
					type: 'tel',
					placeholder: 'Seu celular com DDD',
					onChange: (e) => setFormData({ ...formData, telefone: e.target.value }),
					value: formData.telefone,
				},
			],
		},
		{
			titulo: 'Qual a tua faculdade?',
			codigo: 'P1',
			infos: [
				{
					type: 'search',
					placeholder: 'Buscar faculdade',
					onChange: (e) => {
						let frm = JSON.parse(JSON.stringify(formData));
						frm.respostas[1] = e.target.value;
						setFormData(frm);
					},
					onSelect: (opt) => {
						let frm = JSON.parse(JSON.stringify(formData));
						frm.respostas[1] = opt;
						setFormData(frm);
					},
					value: formData.respostas[1],
					//options: faculdades,
				},
			],
		},
		{
			titulo: 'Quando finalizará a gradução?',
			codigo: 'P2',
			infos: [
				{
					type: 'radio',
					options: [
						'2025 - 1º semestre',
						'2025 - 2º semestre',
						'2026 - 1º semestre',
						'2026 - 2º semestre',
						'2027 - 1º semestre',
						'2027 - 2º semestre',
						'Após 2028',
					],
					onChange: (e) => {
						let frm = JSON.parse(JSON.stringify(formData));
						frm.respostas[2] = e.target.value;
						setFormData(frm);
					},
					value: formData.respostas[2],
				},
			],
		},
		{
			titulo: 'Sente segurança com a formação dada pela faculdade?',
			codigo: 'P3',
			infos: [
				{
					type: 'radio',
					options: [
						'Sim, não percebo lacunas na minha formação.',
						'Ainda não, mas acredito que até a formatura terei.',
						'Não, e sinto que minha formação precisa ser complementada.',
					],
					onChange: (e) => {
						let frm = JSON.parse(JSON.stringify(formData));
						frm.respostas[3] = e.target.value;
						setFormData(frm);
					},
					value: formData.respostas[3],
				},
			],
		},
		{
			titulo: 'Pretende fazer residência médica?',
			codigo: 'P4',
			infos: [
				{
					type: 'radio',
					options: [
						'Sim, farei prova após finalizar a graduação.',
						'Sim, mas pretendo trabalhar um pouco antes e farei prova no ano seguinte.',
						'Não, farei uma pós-graduação.',
						'Ainda não decidi.',
					],
					onChange: (e) => {
						let frm = JSON.parse(JSON.stringify(formData));
						frm.respostas[4] = e.target.value;
						setFormData(frm);
					},

					onChangeTwo: (e) => {
						let frm = JSON.parse(JSON.stringify(formData));
						frm.complementos[4] = e.target.value;
						setFormData(frm);
					},

					value: formData.respostas[4],
				},
			],
		},
		{
			titulo: 'Tu estás a um passo de viver a Medicina Hardwork. Qual o maior benefício que espera disso?',
			codigo: 'P5',
			infos: [
				{
					type: 'radio',
					options: [
						'Fazer parte do time hardwork ajudaria a complementar a renda.',
						'Ter acesso gratuito aos cursos Hardwork.',
						'Melhorar a minha formação médica global e poder contribuir na formação de outras pessoas.',
					],
					onChange: (e) => {
						let frm = JSON.parse(JSON.stringify(formData));
						frm.respostas[5] = e.target.value;
						setFormData(frm);
					},
					value: formData.respostas[5],
				},
			],
		},
	];

	const handleNextStep = () => {
		if (step === 0) {
			getSurveyId();
		} else {
			answerSurvey(FormInfos[step].codigo, formData.respostas[step]);
		}
	};

	const handleNavigation = (step) => {
		setStep(step);
	};

    useEffect(() => {
        step > 0 && step < FormInfos.length ? refForm?.current.scrollIntoView({ behavior: 'smooth' }) : window.scrollTo(0, 0);
    }, [step]);

	// --- FUNÇÃO DA PRIMEIRA REQUISIÇÃO
	// rodar do index 0 para o 1
	const getSurveyId = async () => {
		const body = {
			id_pesquisa: formData.id_pesquisa,
			//id_aluno: 0,
			nome: formData?.nome,
			email: formData?.email,
			telefone: formData?.telefone,
			respostas: [
				{
					pergunta: `PERG0_Nome`,
					resposta: formData?.nome,
				},
				{
					pergunta: `PERG0_Email`,
					resposta: formData?.email,
				},
				{
					pergunta: `PERG0_Telefone`,
					resposta: formData?.telefone,
				},
			],
		};
		if (!formData?.nome || !formData?.email || !formData?.telefone) {
			alert('Por favor informe seus dados');
			return;
		}
		trackPromise(Api.post('Pesquisas', body))
			.then((response) => {
				setFormData({ ...formData, cod_respondente: response });
				setCanPass(true);
				setStep(step + 1);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	// --- FUNÇÃO DAS DEMAIS REQUISIÇÕES
	const answerSurvey = async (codPergunta, resposta) => {
		const body = {
			id_pesquisa: formData.id_pesquisa,
			cod_respondente: formData.cod_respondente,
			respostas: [
				{
					pergunta: codPergunta,
					resposta: resposta,
				},
			],
		};

		if (formData.complementos[step].length > 0) {
			body.respostas.push({
				pergunta: `${codPergunta}_C`,
				resposta: formData.complementos[step],
			});
		}

		if (resposta.length === 0) {
			alert('Por favor informe seus dados');
			return;
		}

		trackPromise(Api.put('Pesquisas', body))
			.then(() => {
				setStep(step + 1);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	return (
		<>
			{!matchesMobile && <Header />}

			<div
				style={{
					backgroundImage: `url(${require('./Assets/bg-legion-full.png')})`,
					fontFamily: 'Manrope',
					color: '#FFFFFF',
					margin: '13px 0',
					backgroundSize: 'cover',
					display: step >= FormInfos.length ? 'flex' : 'none',
					height: '100vh',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					gap: '64px',
				}}
			>
				<div
					style={{
						fontSize: '60px',
						fontWeight: '800',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						textShadow: '4px 4px 4px rgba(0, 0, 0, 0.50)',
					}}
				>
					<p>LEGIÃO</p>
					<p>HARDWORK</p>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						backgroundColor: '#30303080',
						width: matchesMobile ? '90%' : 'fit-content',
						padding: '32px',
						borderRadius: '24px',
						gap: '16px',
						boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
						backdropFilter: 'blur(4px)',
					}}
				>
					<p style={{ fontSize: '25px', fontWeight: 700, lineHeight: '120%' }}>
						Candidatura recebida com sucesso!
					</p>
					<p style={{ fontSize: '16px', fontWeight: 400, lineHeight: '120%' }}>
						Iremos avaliar sua candidatura e em breve entraremos em contato.
					</p>
					<div
						onClick={() => (window.location.href = '/legiao-hardwork')}
						style={{
							display: 'flex',
							width: '100%',
							marginTop: '24px',
							background: 'linear-gradient(0deg, #E11941 0.02%, #E93740 100.05%)',
							justifyContent: 'center',
							alignItems: 'center',
							padding: '10px 0',
							borderRadius: '50px',
							cursor: 'pointer',
						}}
					>
						<p style={{ fontSize: '16px', fontWeight: 700 }}>Concluir</p>
					</div>
				</div>
			</div>

			<main
				style={{
					backgroundImage: `url(${require('./Assets/bg-legion.png')})`,
					fontFamily: 'Manrope',
					color: '#FFFFFF',
					margin: '13px 0',
					display: step < FormInfos.length ? 'flex' : 'none',
					padding: matchesMobile && '0 0 104px 0',
				}}
			>
				<div
					style={{
						width: '100%',
						height: matchesMobile ? '100%' : matchesUpXl ? '100vh' : '',
						display: 'flex',
						flexDirection: matchesMobile ? 'column' : 'row',
					}}
				>
					{/* PRIMEIRA SEÇÃO */}
					<FirstSection mobile={matchesMobile} />
					{/* SEGUNDA SEÇÃO */}
					<section
                        ref={refForm}
						style={{
							width: matchesMobile ? '100%' : '45%',
							height: '100%',
							backgroundImage: `url(${require('./Assets/bg-legion.png')})`,
							backgroundSize: 'cover',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							// padding: !matchesMobile && '158px 0 0 0',
							// backgroundRepeat: 'no-repeat',
						}}
					>
						{/* COMPONENTE DO FORM */}
						<div style={{ width: matchesMobile ? '90%' : '60%', paddingTop: matchesMobile && '104px' }}>
							<Form
								titulo={FormInfos[step]?.titulo}
								infos={FormInfos[step]?.infos}
								onNextStep={handleNextStep}
								isLastStep={step === FormInfos.length - 1}
								step={step}
								mobile={matchesMobile}
								setIsSearching={setIsSearching}
							/>
						</div>
						<div
							style={{
								marginTop: 20,
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								gap: 5,
								zIndex: isSearching && -10,
							}}
						>
							{FormInfos.map((item, index) => (
								<svg
									viewBox="0 0 100 100"
									style={{ height: index === step ? 12 : 8, cursor: 'pointer' }}
									onClick={() => canPass && handleNavigation(index)}
								>
									<circle
										cx="50%"
										cy="50%"
										r="50"
										fill={index === step ? '#fff' : '#ccc'}
									/>
								</svg>
							))}
						</div>
					</section>
				</div>
			</main>

			<Footer />
		</>
	);
}
