import React, { useEffect, useRef, useState } from 'react';
import { Container, Divider, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

// COMPONENTS
import Footer from '../../Components/Footer';
import { Header } from '../../Components/Header';
import TestCardOpen from '../../Components/Cards/TestCardOpen';

// ASSETS
import BagIcon from '../../Assets/Svg/Icons/bagIcon.svg';

// import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import FirstSection from '../../Components/Sections/FirstSection';
import LearningPath from '../../Components/Sections/LearningPath';
import StudentsFeedbackSection from '../../Components/Sections/StudentsFeedback';
import PriceCardVersion3 from '../../Components/Cards/PriceCardVersion3';
import DiferencialBoxV2 from '../../Components/Cards/DiferencialBoxV2';

//Diferentials Assets
import lessionsIcon from '../../Assets/Extensivo/R3CM/lessionsIcon.svg';
import iconResume from '../../Assets/Extensivo/R3CM/iconResume.svg';
import iconQuestions from '../../Assets/Extensivo/R3CM/iconQuestions.svg';
import iconTalkWithProfessor from '../../Assets/Extensivo/R3CM/iconTalkWithProfessor.svg';
import iconLivesBank from '../../Assets/Extensivo/R3CM/iconLivesBank.svg';
import iconBooks from '../../Assets/Extensivo/R3CM/iconBooks.svg';
import iconMetrics from '../../Assets/Extensivo/R3CM/iconMetrics.svg';
import { CountDownTimer } from './Sale/Arena/Arena-sale-2023';

import Api from '../../Services/Api';
import { Helmet } from 'react-helmet';
import PriceCardTrilhaR3 from '../../Components/Cards/PriceCardTrilhaR3';

const learningPathTemplate = [
	{
		title: 'Extensivo Hardwork',
		description: [
			'A trilha do aprendizado funciona por ligas de evolução. Começaremos por temas mais palpáveis, evoluindo para outros mais peculiares.',
			<>
				<br />
				<br />
			</>,
			'São 5 ligas no total, cada uma com temas diferentes.',
			<>
				<br />
				<br />
			</>,
			'Veja como tudo vai funcionar em cada liga:',
		],
		bgColor: '#ffffff',
		titleColor: '#121212',
		descriptionColor: 'primary',
		// url: 'https://player.vimeo.com/video/769930065',
	},
	{
		title: 'Aula Hardwork',
		description: [
			'Tudo começa com a apresentação ao tema, que no Hardwork é feito com rigor em didática.',
			<>
				<br />
				<br />
			</>,
			'São as melhores aulas que você já viu sobre cada tema.',
		],
		bgColor: '#0D0D0D',
		titleColor: 'purple',
		descriptionColor: 'secondary',
		// url: 'https://player.vimeo.com/video/769929590',
	},
	{
		title: 'Estudo por questões',
		description: [
			'Alguns dias após a apresentação ao tema, vamos te conduzir pelo estudo por questões.',
			<>
				<br />
				<br />
			</>,
			'Aqui você entenderá todas as formas em que cada parte do tema é explorado nas provas.',
			<>
				<br />
				<br />
			</>,
			'O professor Hardwork te mostrará tudo em detalhes, aprofundando em cada tema.',
		],
		bgColor: '#0D0D0D',
		titleColor: 'purple',
		descriptionColor: 'secondary',
		// url: 'https://player.vimeo.com/video/769929662',
	},
	{
		title: 'Revisões cíclicas e personalizadas',
		description: [
			'Questões, questões e questões!',
			<br />,
			'É assim que revisamos no Hardwork!',
			<>
				<br />
				<br />
			</>,
			'Toda a dinâmica de revisões é, na verdade, uma continuidade do estudo por questões, explorando com mais ênfase os pontos que você tiver mais dificuldade.',
			<>
				<br />
				<br />
			</>,
			'Todos os temas precisam ser dominados!',
		],
		bgColor: '#0D0D0D',
		titleColor: 'purple',
		descriptionColor: 'secondary',
		// url: 'https://player.vimeo.com/video/769929881',
	},
	{
		title: 'Avaliação de desempenho',
		description: [
			'A regra é dominar temas! A avaliação de desempenho e domínio dos temas será diária, usando métricas do estudo por questões.',
			<>
				<br />
				<br />
			</>,
			'Assim que o grau de suficiência em um tema for atingido, ele estará dominado! Domine todos e migre para a próxima liga de aprendizado.',
			<>
				<br />
				<br />
			</>,
			'"E se eu tiver dificuldade"? Calma! Siga a trilha...',
		],
		bgColor: '#0D0D0D',
		titleColor: 'purple',
		descriptionColor: 'secondary',
		// url: 'https://player.vimeo.com/video/769929824',
	},
];

const DIFERENTIALS_TRILHA = [
	{
		diferential_title: '+ de 100 aulas',
		diferential_description: `Acesso imediato a mais de 100 aulas Hardwork, elaboradas com rigor em didática.`,
		diferential_icon: lessionsIcon,
	},
	{
		diferential_title: '+ de 100 resumos Hardwork',
		diferential_description: `Resumos dos principais temas explorados nas provas de R3 Clínica Médica.`,
		diferential_icon: iconResume,
	},
	{
		diferential_title: '+ de 100 mil questões de provas',
		diferential_description: `Mais de 100 mil questões de provas dos principais concursos de residência do Brasil.`,
		diferential_icon: iconQuestions,
	},
	{
		diferential_title: 'Contato direto com os professores',
		diferential_description: `Não existe escola em que o aluno não fale com o professor! Aqui o contato é direto, todos os alunos têm acesso aos professores via whatsapp.`,
		diferential_icon: iconTalkWithProfessor,
	},
	{
		diferential_title: '+ de 120 lives de discussão ',
		diferential_description: `Acesso imediato ao banco de lives do Hardwork Medicina, com discussões de mais de 120 dos principais temas voltados para provas de R3 Clínica Médica.`,
		diferential_icon: iconLivesBank,
	},
	{
		diferential_title: 'Acesso aos principais livros',
		diferential_description: `Sim! No extensivo tu terás acesso a milhares de obras consagradas...Vamos estudar pelas principais referências. Tudo JUNTO com o professor! Vamos evoluir frase por frase dentro dos problemas explorados pelas questões. Nada de se restringir a textos resumidos! Vamos dominar as principais fontes! Vamos te mostrar como.`,
		diferential_icon: iconBooks,
	},
	{
		diferential_title: 'Métricas de desempenho',
		diferential_description: `Vamos te mostrar toda a tua evolução de acordo com as provas que farás.. Afinal, cada prova tem seus temas de predileção, sua forma de abordar cada assunto.`,
		diferential_icon: iconMetrics,
	},
];

const exams = [
	{
		imageUrl: require('../../Assets/Extensivo/R3CM/logo-sf-usp-r3.svg').default,
	},
	{
		imageUrl: require('../../Assets/Extensivo/R3CM/logo-sf-enare-r3.svg').default,
	},
];

export default function TrilhaR3Cm() {
	const classes = useStyles();
	const theme = useTheme();
	const subscribe_card_trilha_r3 = useRef(null);
	const currentDate = new Date();
	const [endDate, setEndDate] = useState();
	const [turma, setTurma] = useState([]);
	const [showTimer, setShowTimer] = useState(true);

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	useEffect(() => {
		Api.get(`turmasabertas/1454`)
			.then((res) => {
				if (res && res[0]) {
					setTurma(res[0]);
					setShowTimer(res[0].exibe_timer);

					const activeLot = res[0]?.lotes?.find((lot) => {
						return lot?.id === res[0]?.id_lote_vigente;
					});

					if (activeLot) {
						const currentEndDate = activeLot?.data_limite;

						if (currentEndDate) {
							const currentEndDateFormatted = new Date(activeLot?.data_limite);

							setEndDate(currentEndDateFormatted);
						}
					}
				}
			})
			.catch((error) => {
				console.error('Erro ao buscar dados da API:', error);
			});
	}, []);

	const executeScroll = () =>
		subscribe_card_trilha_r3.current.scrollIntoView({ behavior: 'smooth', block: 'center' });

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			<Helmet>
				<title>Trilha R3 Clínica Médica | Estudo por Questões Revolucionário</title>
				<meta
					name="description"
					content="Revolucione sua preparação para a prova de R3 em Clínica Médica com o
Trilha R3. Estudo por questões focado, eficiente e alinhado às suas metas."
				/>
			</Helmet>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
				className="clinica-medica"
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				<FirstSection
					backgroundImageUrl={require('../../Assets/Extensivo/R3CM/bg-trilha-r3-cm.png')}
					logoUrl={require('../../Assets/Extensivo/R3CM/logo-trilha-r3-cm.png')}
					buttonText={turma?.encerrada ? 'Avise-me' : 'Inscreva-se'}
					type="purple"
					executeScroll={executeScroll}
					firstTextPaper={[
						<Typography
							align="left"
							color="secondary"
							className={classes.firstText}
						>
							A revolução do estudo por questões focada na prova de
						</Typography>,
						<Divider
							variant="middle"
							className={classes.divider}
						/>,
						<Typography
							variant="h1"
							className={`${classes.gradientText} clinica-medica gradient-text roboto-condensed`}
						>
							R3 de Clínica Médica
						</Typography>,
						<Typography
							align="left"
							color="secondary"
							className={`${classes.text} poppins`}
						>
							Aqui dominamos todos os temas importantes para as provas de especialidades clinicas. Foco
							total no R3!
						</Typography>,
					]}
					imageProps={{ left: 0, top: '9.75em', width: '55%' }}
				/>
			</Container>

			<LearningPath
				title={'Trilha do aprendizado definitivo'}
				cardsInfo={learningPathTemplate}
				background={'background-gradient-purple'}
			/>

			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Grid
					container
					alignContent="center"
					justifyContent="center"
					className="section"
				>
					<Grid
						item
						xs={12}
						container
					>
						<Grid
							item
							xs={12}
							container
						>
							<Typography
								className={`${classes.subtitle1} roboto-condensed`}
								color="secondary"
							>
								O que mais tem no Trilha
							</Typography>
						</Grid>
						<Grid
							container
							item
							xs={12}
							rowGap={2}
							style={{ margin: '1rem 0rem 3rem 0rem' }}
						>
							{DIFERENTIALS_TRILHA.map((elm, index) => (
								<DiferencialBoxV2
									key={index}
									title={elm.diferential_title}
									description={elm.diferential_description}
									icon={elm.diferential_icon}
									type="cm"
								/>
							))}
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						container
						alignItems={'center'}
						justifyContent={'center'}
					>
						<Grid
							item
							xs={12}
							alignItems={'center'}
							justifyContent={'center'}
						>
							<Grid
								item
								xs={12}
							>
								<Typography
									className={`${classes.subtitle1} roboto-condensed`}
									color="secondary"
									textAlign={'center'}
								>
									E no segundo semestre de 2025
								</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						container
						alignContent="center"
						justifyContent="center"
						style={{ margin: '20px 0px 20px' }}
					>
						{exams.map((item, index) => (
							<Grid
								item
								xs={6}
								md={2.4}
								lg={2.4}
								key={index}
								container
								justifyContent="center"
								style={{ margin: '8px 0' }}
							>
								<TestCardOpen
									imageUrl={item.imageUrl}
									key={index}
									selected={true}
									type={'cm'}
								/>
							</Grid>
						))}
					</Grid>
					<Grid
						item
						xs={12}
						container
						alignContent="center"
						justifyContent="center"
					>
						<Typography
							color="secondary"
							className={`${classes.thirdSectionText} poppins`}
						>
							*Sprints previstos para 2025. Listagem sujeita a alterações para mais ou para menos.
						</Typography>
					</Grid>
				</Grid>
			</Container>

			<Grid
				container
				className="white-background"
			>
				<Grid
					container
					style={{ padding: '2vh 5%' }}
					className={classes.backgroundWaves}
				>
					<Container
						fixed
						maxWidth="xl"
						disableGutters={true}
					>
						<Grid
							container
							alignContent="center"
							justifyContent="center"
							className={`${matchesTablet ? null : 'section'} clinica-price-card`}
							style={{
								backgroundColor: 'black',
								borderRadius: 15,
								margin: matchesTablet ? '40px auto' : '80px auto',
							}}
							ref={subscribe_card_trilha_r3}
						>
							<PriceCardTrilhaR3
								idTurma={1452}
								bgImageUrl={require('../../Assets/Extensivo/R3CM/bg-trilha-r3-price-card.png')}
								logo={require('../../Assets/Extensivo/R3CM/logo-trilha-r3-cm.png')}
								iconBtn={BagIcon}
								color
							/>
						</Grid>
					</Container>
				</Grid>
			</Grid>

			{/* {showTimer === true ? (
				<Grid
					container
					justifyContent="center"
				>
					<Grid
						item
						xs={11}
						md={7}
						lg={7}
						xl={6}
						container
						wrap={matchesMobile ? 'nowrap' : null}
						justifyContent={'space-around'}
						alignContent={'center'}
						className={classes.timerGrid}
						gap={matchesMobile ? 1 : 4}
					>
						<Typography
							color="secondary"
							className={`${classes.endOfDiscount} poppins`}
						>
							<strong style={{ color: '#8F4AE6' }}>Valores promocionais</strong>
							<br />
							se encerram em
						</Typography>

						<CountDownTimer
							show={true}
							targetDate={endDate}
							textColor={'secondary'}
						/>
						<img
							src={require('../../Assets/Extensivo/R3CM/icon-watch-timer-trilha-r3-cm.png')}
							alt=""
							className={classes.timerIcon}
						/>
					</Grid>
				</Grid>
			) : null} */}

			<StudentsFeedbackSection
				type="Trilha R3"
				title="Depoimentos de nossos alunos"
			/>

			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	subtitle1: {
		fontSize: '3.75rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('md')]: {
			lineHeight: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.6rem !important',
			lineHeight: 'unset !important',
		},
	},
	gradientText: {
		fontSize: '3.75rem !important',
		fontWeight: 'bold !important',
		lineHeight: '1.5 !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '2.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.68rem !important',
		},
	},
	text: {
		fontSize: '1.75rem !important',
		lineHeight: '1.25 !important',
		marginTop: '8px !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.35rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
	firstText: {
		fontSize: '3rem !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '2.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.25rem !important',
		},
	},
	textMultimidiaCard: {
		fontSize: '1rem !important',
		fontWeight: 'bold !important',
		alignSelf: 'center',
	},
	backgroundWaves: {
		backgroundImage: `url(${process.env.PUBLIC_URL + '/Assets/Img/bgWave2.png'})`,
		width: '100vw !important',
	},
	divider: {
		backgroundColor: '#6D6D6D',
		minHeight: 1,
		width: '100%',
		margin: '1.5rem 0 1rem !important',
		[theme.breakpoints.down('sm')]: {
			margin: '0.5rem 0 0.5rem !important',
		},
	},
	thirdSectionText: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.75rem !important',
		},
	},
	timerGrid: {
		position: 'fixed',
		bottom: '2rem',
		zIndex: 3,
		border: '3px solid #8F4AE6',
		margin: '0 auto !important',
		backgroundColor: '#000000d9',
		padding: '0.75rem 8rem 0.75rem 4rem',
		borderRadius: '25px 50px 50px 25px !important',
		[theme.breakpoints.down(1770)]: {
			padding: '0.75rem 8rem 0.75rem 4rem',
		},
		[theme.breakpoints.down(1240)]: {
			padding: '0.75rem 9rem 0.75rem 4rem',
		},
		[theme.breakpoints.down('md')]: {
			padding: '0.75rem 2rem 0.75rem 3rem',
			borderRadius: '15px !important',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '0.75rem 1rem',
		},
	},
	endOfDiscount: {
		fontSize: '1.25rem !important',
		alignSelf: 'center',
		[theme.breakpoints.down(1280)]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.8rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.7rem !important',
		},
	},
	timerIcon: {
		position: 'absolute',
		right: -12,
		top: -21,
		[theme.breakpoints.down('xl')]: {
			top: -20,
			right: -11,
			width: '10%',
		},
		[theme.breakpoints.down(1770)]: {
			top: -25,
			right: -10,
			width: 'unset',
		},
		[theme.breakpoints.down(1535)]: {
			top: -21,
			right: -10,
			width: '12%',
		},
		[theme.breakpoints.down(1416)]: {
			top: -16,
			right: -8,
			width: '12%',
		},
		[theme.breakpoints.down(1280)]: {
			top: -18,
			right: -8,
			width: '14%',
		},
		[theme.breakpoints.down(1240)]: {
			top: -22,
			right: -13,
			width: '14%',
		},
		[theme.breakpoints.down(1190)]: {
			width: '15%',
			top: -19,
			right: -9,
		},
		[theme.breakpoints.down(1060)]: {
			width: '16%',
			top: -19,
			right: -9,
		},
		[theme.breakpoints.down(941)]: {
			width: '15%',
		},
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
	},
}));
